import { useCallback, useEffect, useState } from "react";
import Settings from "../../models/settings";

const CameraPavementOptions: React.FC<{ settings: Settings }> = ({
  settings,
}) => {
  const [showDistress, setShowDistress] = useState<boolean>(false);
  const [showDistressBoxes, setShowDistressBoxes] = useState<boolean>(false);
  const [showDistressLabels, setShowDistressLabels] = useState<boolean>(false);
  const [showEvents, setShowEvents] = useState<boolean>(false);

  const [
    canShowPavementMarkingsDuringPlayback,
    setCanShowPavementMarkingsDuringPlayback,
  ] = useState<boolean>(false);
  const [
    showPavementMarkingsDuringPlayback,
    setShowPavementMarkingsDuringPlayback,
  ] = useState<boolean>(false);

  useEffect(() => {
    setShowDistress(settings.showPavementDistress);
    setShowDistressBoxes(settings.showPavementDistressBoxes);
    setShowDistressLabels(settings.showPavementDistressLabels);
    setShowEvents(settings.showPavementEvents);
    setShowPavementMarkingsDuringPlayback(
      settings.showPavementMarkingsDuringPlayback
    );
  }, [settings]);

  const invokeShowPavementMarkingsDuringPlaybackChanged = useCallback(
    (checked: boolean) => {
      if (settings.onShowPavementMarkingsDuringPlaybackChanged) {
        settings.onShowPavementMarkingsDuringPlaybackChanged(checked);
      }
    },
    [settings]
  );

  const onSettingsChanged = useCallback(() => {
    if (settings.onSettingsChanged) {
      settings.onSettingsChanged(settings);
    }
  }, [settings]);

  useEffect(() => {
    const canShowPavementMarkingsDuringPlayback = showDistress || showEvents;
    setCanShowPavementMarkingsDuringPlayback(
      canShowPavementMarkingsDuringPlayback
    );

    if (!canShowPavementMarkingsDuringPlayback) {
      settings.showPavementMarkingsDuringPlayback = false;
      setShowPavementMarkingsDuringPlayback(false);
      invokeShowPavementMarkingsDuringPlaybackChanged(false);

      onSettingsChanged();
    }
  }, [
    showDistress,
    showEvents,
    settings,
    invokeShowPavementMarkingsDuringPlaybackChanged,
    onSettingsChanged,
  ]);

  const onShowDistressChanged = (event: any) => {
    const checked = event.target.checked;
    setShowDistress(checked);

    settings.showPavementDistress = checked;

    if (settings.showPavementDistress) {
      setShowDistressBoxes(true);
      setShowDistressLabels(true);

      settings.showPavementDistressBoxes = true;
      invokeShowPavementDistressBoxesChanged(true);

      settings.showPavementDistressLabels = true;
      invokeShowPavementDistressLabelsChanged(true);
    } else {
      setShowDistressBoxes(false);
      setShowDistressLabels(false);
      setShowPavementMarkingsDuringPlayback(false);

      settings.showPavementDistressBoxes = false;
      invokeShowPavementDistressBoxesChanged(false);
      settings.showPavementDistressLabels = false;
      invokeShowPavementDistressLabelsChanged(false);
    }

    invokeShowPavementDistressChanged(checked);

    onSettingsChanged();
  };

  const invokeShowPavementDistressChanged = (checked: boolean) => {
    if (settings.onShowPavementDistressChanged) {
      settings.onShowPavementDistressChanged(checked);
    }
  };

  const onShowDistressBoxesChanged = (event: any) => {
    const checked = event.target.checked;
    setShowDistressBoxes(checked);

    settings.showPavementDistressBoxes = checked;

    invokeShowPavementDistressBoxesChanged(checked);

    onSettingsChanged();
  };

  const invokeShowPavementDistressBoxesChanged = (checked: boolean) => {
    if (settings.onShowPavementDistressBoxesChanged) {
      settings.onShowPavementDistressBoxesChanged(checked);
    }
  };

  const onShowDistressLabelsChanged = (event: any) => {
    const checked = event.target.checked;
    setShowDistressLabels(checked);

    settings.showPavementDistressLabels = checked;

    invokeShowPavementDistressLabelsChanged(checked);

    onSettingsChanged();
  };

  const invokeShowPavementDistressLabelsChanged = (checked: boolean) => {
    if (settings.onShowPavementDistressLabelsChanged) {
      settings.onShowPavementDistressLabelsChanged(checked);
    }
  };

  const onShowEventsChanged = (event: any) => {
    const checked = event.target.checked;
    setShowEvents(checked);

    settings.showPavementEvents = checked;

    invokeShowEventsChanged(checked);

    onSettingsChanged();
  };

  const invokeShowEventsChanged = (checked: boolean) => {
    if (settings.onShowPavementEventsChanged) {
      settings.onShowPavementEventsChanged(checked);
    }
  };

  const onShowPavementMarkingsDuringPlaybackChanged = (event: any) => {
    const checked = event.target.checked;
    setShowPavementMarkingsDuringPlayback(checked);

    settings.showPavementMarkingsDuringPlayback = checked;

    invokeShowPavementMarkingsDuringPlaybackChanged(checked);

    onSettingsChanged();
  };

  return (
    <div>
      <div className="dropdown-item-padding" style={{ fontWeight: "bold" }}>
        Pavement Camera Options
      </div>
      <div className="dropdown-item-padding d-flex">
        <div className="d-flex align-items-center">
          <label className="form-check-label" htmlFor="showDistress">
            Show Distress
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showDistress}
              onChange={onShowDistressChanged}
              id="showDistress"
            />
          </div>
        </div>
        <div className="d-flex align-items-center ms-2">
          <label className="form-check-label" htmlFor="showDistressBoxes">
            Boxes
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showDistressBoxes}
              onChange={onShowDistressBoxesChanged}
              id="showDistressBoxes"
              disabled={!showDistress}
            />
          </div>
        </div>
        <div className="d-flex align-items-center ms-2">
          <label className="form-check-label" htmlFor="showDistressLabels">
            Labels
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showDistressLabels}
              onChange={onShowDistressLabelsChanged}
              id="showDistressLabels"
              disabled={!showDistress}
            />
          </div>
        </div>
      </div>
      <div className="dropdown-item-padding d-flex">
        <div className="d-flex align-items-center">
          <label className="form-check-label" htmlFor="showEvents">
            Show Events
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showEvents}
              onChange={onShowEventsChanged}
              id="showEvents"
            />
          </div>
        </div>
      </div>
      <div className="dropdown-item-padding d-flex">
        <div className="d-flex align-items-center">
          <label
            className="form-check-label"
            htmlFor="showDistressDuringPlayback"
          >
            Show Pavement Markings During Playback
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showPavementMarkingsDuringPlayback}
              onChange={onShowPavementMarkingsDuringPlaybackChanged}
              id="showDistressDuringPlayback"
              disabled={!canShowPavementMarkingsDuringPlayback}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraPavementOptions;
