import { useCallback, useContext, useEffect, useState } from "react";
import Dropdown from "../../common/Dropdown";
import ProjectInfo from "../../../models/projectInfo";
import TableColum from "../../../models/tableColumn";
import DropdownOption from "../../common/DropdownOption";
import ThematicRangesEditor from "./ThematicRangesEditor";
import ThematicValuesEditor from "./ThematicValuesEditor";
import unitConverter from "../../../util/units/unitConverter";
import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";
import TableColumnDataType from "../../../models/tableColumnDataType";
import MapLayer from "../../../models/mapLayer";
import ModalWindow from "../../common/overlays/ModalWindow";
import ThematicMapLayerRangeType from "../../../models/thematicMapLayerRangeType";
import ValidatorManager from "../../../components/common/validationManager";
import Validator from "../../common/Validator";
import User from "../../../models/user";
import UserContext from "../../../contexts/userContext";
import MapLayerShareIcon from "../MapLayerShareIcon";

const validator = {
  layerName: "layerName",
};

const ThematicMapLayerEdit: React.FC<{
  project: ProjectInfo;
  tableColumns: TableColum[];
  mapLayer: MapLayer;
  isNew: boolean;
  onCreateMapLayer: any;
  onUpdateMapLayer: any;
  onDeleteMapLayer: any;
  onCancel: any;
}> = ({
  project,
  tableColumns,
  mapLayer: inputMapLayer,
  isNew,
  onCreateMapLayer,
  onUpdateMapLayer,
  onDeleteMapLayer,
  onCancel,
}) => {
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);

  const [showConfirmIsShared, setShowConfirmIsShared] =
    useState<Boolean>(false);

  const [showConfirmIsDefault, setShowConfirmIsDefault] =
    useState<Boolean>(false);

  const [showConfirmSave, setShowConfirmSave] = useState<Boolean>(false);

  const user = useContext<User | undefined>(UserContext);

  const [validatorManager] = useState<ValidatorManager>(new ValidatorManager());

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [fieldOptions, setFieldOptions] = useState<
    DropdownOption<TableColum>[]
  >([]);
  const [selectedFieldOption, setSelectedFieldOption] =
    useState<DropdownOption<TableColum>>();

  const [mapLayer, setMapLayer] = useState<MapLayer>(new MapLayer());

  const setNewThematicLayer = useCallback(
    (
      mapLayer: MapLayer,
      tableColumn: TableColum,
      thematicMapLayerRanges: ThematicMapLayerRange[]
    ) => {
      if (!mapLayer.thematicMapLayer) {
        return;
      }
      const newMapLayer = { ...mapLayer };
      newMapLayer.thematicMapLayer = { ...mapLayer.thematicMapLayer };
      newMapLayer.thematicMapLayer.fieldName = tableColumn.columnName;

      if (isDataTypeContinous(tableColumn.dataType)) {
        newMapLayer.thematicMapLayer.rangeType =
          ThematicMapLayerRangeType.Continuous;
      } else if (isDataTypeDiscrete(tableColumn.dataType)) {
        newMapLayer.thematicMapLayer.rangeType =
          ThematicMapLayerRangeType.Discrete;
      }

      newMapLayer.thematicMapLayer.thematicMapLayerRanges =
        thematicMapLayerRanges;

      setMapLayer(newMapLayer);
    },
    []
  );

  const setFieldOptionsFromMapLayer = useCallback(
    (
      mapLayer: MapLayer,
      tableColumns: TableColum[],
      fieldOptions: DropdownOption<TableColum>[]
    ) => {
      const layerFieldName = mapLayer.thematicMapLayer?.fieldName;

      const layerTableColumn = tableColumns.find(
        (c) => c.columnName === layerFieldName
      );

      if (layerTableColumn) {
        const layerFieldOption = {
          value: layerTableColumn,
          label: layerTableColumn.displayName,
        };

        setSelectedFieldOption(layerFieldOption);
      } else {
        const selectedFieldOption = fieldOptions[0];
        setSelectedFieldOption(selectedFieldOption);

        if (selectedFieldOption.value) {
          setNewThematicLayer(mapLayer, selectedFieldOption.value, []);
        }
      }
    },
    [setNewThematicLayer]
  );

  useEffect(() => {
    setMapLayer(inputMapLayer);

    const options: DropdownOption<TableColum>[] = tableColumns.map((c) => {
      return { value: c, label: c.displayName };
    });

    setFieldOptions(options);

    setFieldOptionsFromMapLayer(inputMapLayer, tableColumns, options);

    if (!isNew) {
      validatorManager.setIsValid(validator.layerName, true);
    }
  }, [
    project,
    tableColumns,
    inputMapLayer,
    isNew,
    setFieldOptionsFromMapLayer,
    validatorManager,
  ]);

  const onThematicMapLayerRangesChanged = (
    thematicMapLayerRanges: ThematicMapLayerRange[]
  ) => {
    if (!mapLayer.thematicMapLayer) {
      return;
    }

    if (!selectedFieldOption || !selectedFieldOption.value) {
      return;
    }

    setNewThematicLayer(
      mapLayer,
      selectedFieldOption.value,
      thematicMapLayerRanges
    );
  };

  const onLayerNameChanged = (event: any) => {
    const newValue = event.target.value;

    const newMapLayer = { ...mapLayer, layerName: newValue };
    setMapLayer(newMapLayer);

    const isValid = newValue.length !== 0;
    validatorManager.setIsValid(validator.layerName, isValid);
  };

  const onFieldOptionChaged = async (newValue: DropdownOption<TableColum>) => {
    setSelectedFieldOption(newValue);

    if (!newValue.value) {
      return;
    }

    setNewThematicLayer(mapLayer, newValue.value, []);
  };

  const onSave = async () => {
    setErrorMessage("");

    if (!validatorManager.validate()) {
      console.log("return");
      return;
    }

    try {
      if (isNew) {
        await onCreateMapLayer(mapLayer);
      } else {
        await onUpdateMapLayer(mapLayer);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = async () => {
    setShowDeleteWindow(false);

    onDeleteMapLayer(mapLayer);
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const isDataTypeContinous = (dataType: TableColumnDataType) => {
    return dataType === TableColumnDataType.Number;
  };

  const isDataTypeDiscrete = (dataType: TableColumnDataType) => {
    return (
      dataType === TableColumnDataType.String ||
      dataType === TableColumnDataType.Boolean
    );
  };

  // --------- IsShared

  const onIsSharedChanged = (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      onConfirmIsShared();
      return;
    }

    const newMapLayer = { ...mapLayer, isShared: false };
    setMapLayer(newMapLayer);
  };

  const onConfirmIsShared = () => {
    setShowConfirmIsShared(true);
  };

  const onOkIsShared = () => {
    setShowConfirmIsShared(false);

    const newMapLayer = { ...mapLayer, isShared: true };
    setMapLayer(newMapLayer);
  };

  const onCancelIsShared = () => {
    setShowConfirmIsShared(false);
  };

  /////////////////////

  // --------- IsDefault

  const onIsDefaultChanged = (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      onConfirmIsDefault();
      return;
    }

    const newMapLayer = { ...mapLayer, isDefault: false, isShared: false };
    setMapLayer(newMapLayer);
  };

  const onConfirmIsDefault = () => {
    setShowConfirmIsDefault(true);
  };

  const onOkIsDefault = () => {
    setShowConfirmIsDefault(false);

    const newMapLayer = { ...mapLayer, isDefault: true, isShared: true };
    setMapLayer(newMapLayer);
  };

  const onCancelIsDefault = () => {
    setShowConfirmIsDefault(false);
  };

  /////////////////////

  // --------- Save

  const onConfirmSave = () => {
    if (mapLayer.isShared || mapLayer.isDefault) {
      setShowConfirmSave(true);
    } else {
      onOkSave();
    }
  };

  const onOkSave = async () => {
    setShowConfirmSave(false);

    await onSave();
  };

  const onCancelSave = () => {
    setShowConfirmSave(false);
  };

  /////////////////////

  const onResetToDefault = () => {
    if (!mapLayer.parentMapLayer) {
      return;
    }

    const defaultMapLayer = structuredClone(mapLayer.parentMapLayer);

    if (!defaultMapLayer) {
      return;
    }

    defaultMapLayer.isShared = false;
    defaultMapLayer.isDefault = false;
    defaultMapLayer.isSharedDataChanged = false;

    defaultMapLayer.idMapLayer = mapLayer.idMapLayer;
    defaultMapLayer.idUser = mapLayer.idUser;
    defaultMapLayer.idParentMapLayer = mapLayer.idParentMapLayer;
    defaultMapLayer.parentMapLayer = mapLayer.parentMapLayer;

    // network layer

    defaultMapLayer.networkMapLayer = mapLayer.networkMapLayer;

    // thematic layer

    setFieldOptionsFromMapLayer(defaultMapLayer, tableColumns, fieldOptions);

    if (defaultMapLayer.thematicMapLayer && mapLayer.thematicMapLayer) {
      defaultMapLayer.thematicMapLayer.idThematicMapLayer =
        mapLayer.thematicMapLayer.idThematicMapLayer;
      defaultMapLayer.thematicMapLayer.idMapLayer =
        mapLayer.thematicMapLayer.idMapLayer;

      // mark thematic ranges as new
      for (const range of defaultMapLayer.thematicMapLayer
        .thematicMapLayerRanges) {
        range.idThematicMapLayerRange = 0;
        range.idThematicMapLayer = mapLayer.thematicMapLayer.idThematicMapLayer;
      }
    }

    setMapLayer(defaultMapLayer);
  };

  const getSharedOrDefaultLabel = () => {
    if (mapLayer.isDefault) {
      return "Default";
    }

    if (mapLayer.isShared) {
      return "Shared";
    }

    return "";
  };

  return (
    <div style={{ background: "white", padding: "10px", minWidth: "300px" }}>
      <div className="row">
        <div className="mb-3 d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-center">
            <div style={{ textWrap: "nowrap" }}>
              <b>
                {isNew
                  ? "New Layer"
                  : `Edit ${getSharedOrDefaultLabel()} Layer`}
              </b>
            </div>
            <MapLayerShareIcon mapLayer={mapLayer} />
            {!mapLayer.parentMapLayer && (
              <div className="d-flex align-items-center ms-2">
                <label className="form-check-label" htmlFor="isShared">
                  Share
                </label>
                <div className="form-switch form-switch-right-text">
                  <input
                    className="form-check-input form-switch-right-text"
                    type="checkbox"
                    checked={mapLayer.isShared}
                    onChange={onIsSharedChanged}
                    id="isShared"
                    disabled={mapLayer.isDefault}
                  />
                </div>
                {showConfirmIsShared && (
                  <ModalWindow
                    title="Share Layer"
                    onOk={onOkIsShared}
                    onCancel={onCancelIsShared}
                  >
                    <div>
                      Are you sure you want to share layer to be visible by all
                      users?
                    </div>
                  </ModalWindow>
                )}
              </div>
            )}
          </div>
          {user?.isPortalAdmin && !mapLayer.parentMapLayer && (
            <div className="d-flex align-items-center ms-3">
              <div className="badge bg-secondary me-2">PORTAL ADMIN</div>
              <label
                className="form-check-label"
                style={{ textWrap: "nowrap" }}
                htmlFor="isDefaultLayer"
              >
                Project Default Layer
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={mapLayer.isDefault}
                  onChange={onIsDefaultChanged}
                  id="isDefaultLayer"
                />
              </div>
              {showConfirmIsDefault && (
                <ModalWindow
                  title="Set Project Default Map Layer"
                  onOk={onOkIsDefault}
                  onCancel={onCancelIsDefault}
                >
                  <div>
                    Are you sure you want to set the map layer as a project
                    default map layer to be visible by all users?
                  </div>
                </ModalWindow>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="mb-3">
          <label htmlFor="layerName" className="form-label">
            Layer Name
          </label>
          <div className="input-group">
            <Validator
              name={validator.layerName}
              errorMessage="Please enter a layer name."
              validationManager={validatorManager}
            >
              <input
                type="text"
                className="form-control"
                id="layerName"
                value={mapLayer.layerName}
                onChange={onLayerNameChanged}
              />
            </Validator>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mb-3">
          <label htmlFor="field" className="form-label">
            Field
          </label>
          <div className="d-flex align-items-center mb-3">
            <Dropdown
              minWidth="200px"
              options={fieldOptions}
              value={selectedFieldOption}
              onChange={(newValue: any) => onFieldOptionChaged(newValue)}
              disabled={mapLayer.parentMapLayer}
            />
            {selectedFieldOption && (
              <div className="ms-2">
                {selectedFieldOption.value &&
                  unitConverter.getSymbol(
                    selectedFieldOption.value.unitMeasure
                  )}
              </div>
            )}
          </div>
          {selectedFieldOption &&
            selectedFieldOption.value &&
            isDataTypeContinous(selectedFieldOption.value.dataType) && (
              <ThematicRangesEditor
                tableColumn={selectedFieldOption.value}
                thematicMapLayerRanges={
                  mapLayer.thematicMapLayer?.thematicMapLayerRanges ?? []
                }
                onThematicMapLayerRangesChanged={
                  onThematicMapLayerRangesChanged
                }
              />
            )}
          {selectedFieldOption &&
            selectedFieldOption.value &&
            isDataTypeDiscrete(selectedFieldOption.value.dataType) && (
              <ThematicValuesEditor
                project={project}
                tableColumn={selectedFieldOption.value}
                thematicMapLayerRanges={
                  mapLayer.thematicMapLayer?.thematicMapLayerRanges ?? []
                }
                onThematicMapLayerRangesChanged={
                  onThematicMapLayerRangesChanged
                }
              />
            )}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <button className="btn btn-sm btn-primary" onClick={onConfirmSave}>
            {isNew ? "Create" : "Save"}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </button>
          <div className="col-md-12 text-center mt-3">
            {errorMessage !== "" && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
        </div>
        {!isNew && !mapLayer.parentMapLayer && (
          <div>
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={onDeleteConfirm}
            >
              Delete
            </button>
          </div>
        )}
        {mapLayer.parentMapLayer && (
          <div>
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={onResetToDefault}
            >
              Reset to Default
            </button>
          </div>
        )}
      </div>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>
            {`Are you sure you want to delete the map layer: ${mapLayer?.layerName}?`}
          </div>
        </ModalWindow>
      )}
      {showConfirmSave && (
        <ModalWindow
          title={isNew ? "Create" : "Save"}
          onOk={onOkSave}
          onCancel={onCancelSave}
        >
          <div>
            You are {isNew ? "creating" : "editing"} a{" "}
            <span className="text-danger">
              <b>{`${getSharedOrDefaultLabel()} Layer`}</b>
            </span>
            .{" "}
            <b>
              {isNew ? "The layer" : "Changes"} will be visible to all users.
            </b>
            <br />
            Are you sure you want to{" "}
            {isNew ? "create the layer" : "save changes"}?
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default ThematicMapLayerEdit;
