import TableColum from "../../../models/tableColumn";
import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";
import mapLayerService from "../../../services/mapLayerService";
import Unit from "../../../util/units/unit";

enum RangeTemplate {
  Percentage = "Percentage",
  Iri = "IRI",
  Sdi = "Surface Distress Index (SDI)",
  Pci = "Pavement Condition Index (PCI)",
  Si = "Structural Index (SI)",
  Ri = "Roughness Index (RI)",
  Pcsi = "PCSI",
  Pscpri = "PSCPRI",
}

class ThematicRangesTemplateGenerator {
  public generateIriTemplateRanges = (
    tableColumn: TableColum
  ): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // Good: IRI up to 95 inches/mi (1.50 m/km). Fair: IRI up to 170 inches/mi (2.68 m/km). Poor: IRI up to 220 inches/mile (3.47 m/km). Very poor: IRI exceeding 220 inches/mi (3.47 m/km)

    // in/mi

    // RangeLabel	RangeColor	HasRangeStart	HasRangeEnd	RangeStartValue	RangeEndValue
    // Good	#7ed321	0	1	0	95
    // Fair	#f5a623	1	1	95  170
    // Poor	#d0021b	1	0	170 0

    // si m/km (db)

    // RangeLabel	RangeColor	HasRangeStart	HasRangeEnd	RangeStartValue	RangeEndValue
    // Good	#7ed321	0	1	0	1.49936870712936
    // Fair	#f5a623	1	1	1.49936870712936	2.68308084433674
    // Poor	#d0021b	1	0	2.68308084433674	0

    // si m/km (docs)

    // RangeLabel	RangeColor	HasRangeStart	HasRangeEnd	RangeStartValue	RangeEndValue
    // Good	#7ed321	0	1	0	1.50
    // Fair	#f5a623	1	1	1.50	2.68
    // Poor	#d0021b	1	0	2.68	0

    // from web service
    // [
    //   {
    //     "idThematicMapLayerRange": 47,
    //     "idThematicMapLayer": 11,
    //     "rangeLabel": "Good",
    //     "rangeColor": "#7ed321",
    //     "hasRangeStart": false,
    //     "hasRangeEnd": true,
    //     "rangeStartValue": 0,
    //     "rangeEndValue": 1.4993687071293562,
    //     "rangeValue": "",
    //     "index": 0
    //   },
    //   {
    //     "idThematicMapLayerRange": 48,
    //     "idThematicMapLayer": 11,
    //     "rangeLabel": "Fair",
    //     "rangeColor": "#f5a623",
    //     "hasRangeStart": true,
    //     "hasRangeEnd": true,
    //     "rangeStartValue": 1.4993687071293562,
    //     "rangeEndValue": 2.6830808443367427,
    //     "rangeValue": "",
    //     "index": 0
    //   },
    //   {
    //     "idThematicMapLayerRange": 49,
    //     "idThematicMapLayer": 11,
    //     "rangeLabel": "Poor",
    //     "rangeColor": "#d0021b",
    //     "hasRangeStart": true,
    //     "hasRangeEnd": false,
    //     "rangeStartValue": 2.6830808443367427,
    //     "rangeEndValue": 0,
    //     "rangeValue": "",
    //     "index": 0
    //   }
    // ]

    const useRounding = tableColumn.unitMeasure !== Unit.InchPerMile;

    let newRange = new ThematicMapLayerRange();
    let index = 1;

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Good";
    newRange.rangeColor = "#7ed321";
    newRange.hasRangeStart = false;
    newRange.hasRangeEnd = true;
    if (!useRounding) {
      // use these values so they get converted properly to 95, 170
      newRange.rangeStartValue = 0;
      newRange.rangeEndValue = 1.4993687071293562;
    } else {
      // use directly the numbers, instead of rounding to make them comply with the standard
      // Good: IRI up to 95 inches/mi (1.50 m/km). Fair: IRI up to 170 inches/mi (2.68 m/km). Poor: IRI up to 220 inches/mile (3.47 m/km). Very poor: IRI exceeding 220 inches/mi (3.47 m/km)
      newRange.rangeStartValue = 0;
      newRange.rangeEndValue = 1.5;
    }
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Fair";
    newRange.rangeColor = "#f5a623";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;
    if (!useRounding) {
      newRange.rangeStartValue = 1.4993687071293562;
      newRange.rangeEndValue = 2.6830808443367427;
    } else {
      newRange.rangeStartValue = 1.5;
      newRange.rangeEndValue = 2.68;
    }
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Poor";
    newRange.rangeColor = "#d0021b";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = false;
    if (!useRounding) {
      newRange.rangeStartValue = 2.6830808443367427;
      newRange.rangeEndValue = 0;
    } else {
      newRange.rangeStartValue = 2.68;
      newRange.rangeEndValue = 0;
    }
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    mapLayerService.fixRangesDisplayValues(newRanges, tableColumn);

    return newRanges;
  };

  public generatePercentageTemplateRanges = (): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // RangeLabel	RangeColor	HasRangeStart	HasRangeEnd	RangeStartValue	RangeEndValue
    // Excellent	#7ed321	1	1	85	100
    // Good	#417505	1	1	70	85
    // Fair	#f5a623	1	1	55	70
    // Poor	#d0021b	1	1	40	55
    // Very Poor	#4a4a4a	1	1	0	40

    let newRange = new ThematicMapLayerRange();
    let index = 1;

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Excellent";
    newRange.rangeColor = "#7ed321";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;
    newRange.rangeStartValue = 85;
    newRange.rangeEndValue = 100;
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Good";
    newRange.rangeColor = "#417505";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;
    newRange.rangeStartValue = 70;
    newRange.rangeEndValue = 85;
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Fair";
    newRange.rangeColor = "#f5a623";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;
    newRange.rangeStartValue = 55;
    newRange.rangeEndValue = 70;
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Poor";
    newRange.rangeColor = "#d0021b";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;
    newRange.rangeStartValue = 40;
    newRange.rangeEndValue = 55;
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = "Very Poor";
    newRange.rangeColor = "#4a4a4a";
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;
    newRange.rangeStartValue = 0;
    newRange.rangeEndValue = 40;
    newRange.index = index;
    index++;
    newRanges.push(newRange);

    mapLayerService.fixRangesDisplayValuesNoUnitConversion(newRanges);

    return newRanges;
  };

  private genRange = (
    start: number,
    end: number,
    label: string,
    color: string,
    index: number
  ) => {
    return this.genRange2(start, end, label, color, index, true, true);
  };

  private genRange2 = (
    start: number,
    end: number,
    label: string,
    color: string,
    index: number,
    hasRangeStart: boolean,
    hasRangeEnd: boolean
  ) => {
    const newRange = new ThematicMapLayerRange();
    newRange.rangeLabel = label;
    newRange.rangeColor = color;
    newRange.hasRangeStart = hasRangeStart;
    newRange.hasRangeEnd = hasRangeEnd;
    newRange.rangeStartValue = start;
    newRange.rangeEndValue = end;
    newRange.index = index;
    return newRange;
  };

  public generateSdiTemplateRanges = (): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // 85-100 Excellent (Hex 002060)
    // 70-85 Very Good (00B050)
    // 60-70 Good (92D050)
    // 50-60 Fair (FFFF00)
    // 40-50 Marginal (FFC000)
    // 25-40 Poor (FF0000)
    // 0-25 Very Poor (C00000)

    let index = 0;

    newRanges.push(this.genRange(85, 100, "Excellent", "#002060", ++index));
    newRanges.push(this.genRange(70, 85, "Very Good", "#00B050", ++index));
    newRanges.push(this.genRange(60, 70, "Good", "#92D050", ++index));
    newRanges.push(this.genRange(50, 60, "Fair", "#FFFF00", ++index));
    newRanges.push(this.genRange(40, 50, "Marginal", "#FFC000", ++index));
    newRanges.push(this.genRange(25, 40, "Poor", "#FF0000", ++index));
    newRanges.push(this.genRange(0, 25, "Very Poor", "#C00000", ++index));

    mapLayerService.fixRangesDisplayValuesNoUnitConversion(newRanges);

    return newRanges;
  };

  public generatePciTemplateRanges = (): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // 85-100 Excellent (Hex 002060)
    // 70-85 Very Good (00B050)
    // 60-70 Good (92D050)
    // 50-60 Fair (FFFF00)
    // 40-50 Marginal (FFC000)
    // 25-40 Poor (FF0000)
    // 0-25 Very Poor (C00000)

    let index = 0;

    newRanges.push(this.genRange(85, 100, "Excellent", "#002060", ++index));
    newRanges.push(this.genRange(70, 85, "Very Good", "#00B050", ++index));
    newRanges.push(this.genRange(60, 70, "Good", "#92D050", ++index));
    newRanges.push(this.genRange(50, 60, "Fair", "#FFFF00", ++index));
    newRanges.push(this.genRange(40, 50, "Marginal", "#FFC000", ++index));
    newRanges.push(this.genRange(25, 40, "Poor", "#FF0000", ++index));
    newRanges.push(this.genRange(0, 25, "Very Poor", "#C00000", ++index));

    mapLayerService.fixRangesDisplayValuesNoUnitConversion(newRanges);

    return newRanges;
  };

  public generateSiTemplateRanges = (): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // RangeStart <= RangeEnd

    // <30 Weak (Hex C00000)
    // 30-60 Moderate (FFC000)
    // >60 Strong (00B050)

    let index = 0;

    newRanges.push(
      this.genRange2(0, 30, "Weak", "#C00000", ++index, false, true)
    );

    newRanges.push(
      this.genRange2(30, 60, "Moderate", "#FFC000", ++index, true, true)
    );

    newRanges.push(
      this.genRange2(60, 0, "Strong", "#00B050", ++index, true, false)
    );

    mapLayerService.fixRangesDisplayValuesNoUnitConversion(newRanges);

    return newRanges;
  };

  public generateRiTemplateRanges = (): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // RangeStart <= RangeEnd

    // <47.3 Rough (Hex C00000)
    // 47.3-71.5 Marginal (FFC000)
    // >71.5 Smooth (00B050)

    let index = 0;

    newRanges.push(
      this.genRange2(0, 47.3, "Rough", "#C00000", ++index, false, true)
    );

    newRanges.push(
      this.genRange2(47.3, 71.5, "Marginal", "#FFC000", ++index, true, true)
    );

    newRanges.push(
      this.genRange2(71.5, 0, "Smooth", "#00B050", ++index, true, false)
    );

    mapLayerService.fixRangesDisplayValuesNoUnitConversion(newRanges);

    return newRanges;
  };

  public generatePcsiTemplateRanges = (): ThematicMapLayerRange[] => {
    const newRanges: ThematicMapLayerRange[] = [];

    // 92-100 Excellent (Hex 002060)
    // 80-92 Good (92D050)
    // 70-80 Fair (FFFF00)
    // 50-70 Poor (FF0000)
    // 0-50 Very Poor (C00000)

    let index = 0;

    newRanges.push(this.genRange(92, 100, "Excellent", "#002060", ++index));
    newRanges.push(this.genRange(80, 92, "Good", "#92D050", ++index));
    newRanges.push(this.genRange(70, 80, "Fair", "#FFFF00", ++index));
    newRanges.push(this.genRange(50, 70, "Poor", "#FF0000", ++index));
    newRanges.push(this.genRange(0, 50, "Very Poor", "#C00000", ++index));

    mapLayerService.fixRangesDisplayValuesNoUnitConversion(newRanges);

    return newRanges;
  };

  public generatePscpriTemplateRanges = (): ThematicMapLayerRange[] => {
    return this.generatePcsiTemplateRanges();
  };
}

export { RangeTemplate, ThematicRangesTemplateGenerator };
