import ThematicMapLayerRange from "../../../models/thematicMapLayerRange";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ColorPicker from "../../common/ColorPicker";
import nameOf from "../../../util/nameOfHelper";
import TableColum from "../../../models/tableColumn";
import unitConverter from "../../../util/units/unitConverter";
import colorHelper from "../../../util/colorHelper";
import Dropdown from "../../common/Dropdown";
import DropdownOption from "../../common/DropdownOption";
import { useEffect, useState } from "react";
import Unit from "../../../util/units/unit";
import {
  RangeTemplate,
  ThematicRangesTemplateGenerator,
} from "./thematicRangesTemplatesGenerator";
import stringHelper from "../../../util/stringHelper";

const ThematicRangesEditor: React.FC<{
  tableColumn: TableColum;
  thematicMapLayerRanges: ThematicMapLayerRange[];
  onThematicMapLayerRangesChanged: any;
}> = ({
  tableColumn,
  thematicMapLayerRanges,
  onThematicMapLayerRangesChanged,
}) => {
  const [templatesOptions, setTemplateOptions] = useState<
    DropdownOption<RangeTemplate>[]
  >([]);
  const [selectedTemplateOption, setSelectedTemplateOption] =
    useState<DropdownOption<RangeTemplate>>();

  useEffect(() => {
    const addTemplate = (
      fieldNamePatterns: string[],
      fieldNamePatternExacts: string[],
      rangeTemplate: RangeTemplate,
      templatesOptions: DropdownOption<RangeTemplate>[]
    ) => {
      let found = false;
      for (const fieldNamePattern of fieldNamePatterns) {
        if (
          stringHelper.stringContainsIgnoreCase(
            tableColumn.displayName,
            fieldNamePattern
          )
        ) {
          found = true;
          break;
        }
      }

      if (!found) {
        for (const fieldNamePattern of fieldNamePatternExacts) {
          if (
            tableColumn.displayName.toLowerCase().trim() ===
            fieldNamePattern.toLowerCase().trim()
          ) {
            found = true;
            break;
          }
        }
      }

      if (found) {
        const templateOption = new DropdownOption<RangeTemplate>();
        templateOption.label = rangeTemplate;
        templateOption.value = rangeTemplate;
        templatesOptions.push(templateOption);
      }
    };

    const templatesOptions: DropdownOption<RangeTemplate>[] = [];

    let templateOption = new DropdownOption<RangeTemplate>();

    if (tableColumn.unitMeasure) {
      if (
        tableColumn.unitMeasure === Unit.MeterPerKilometer ||
        tableColumn.unitMeasure === Unit.InchPerMile ||
        tableColumn.unitMeasure === Unit.MillimeterPerMeter
      ) {
        templateOption = new DropdownOption<RangeTemplate>();
        templateOption.label = RangeTemplate.Iri;
        templateOption.value = RangeTemplate.Iri;
        templatesOptions.push(templateOption);
      }
    }

    addTemplate(
      ["Surface Distress Index", " SDI ", "(SDI)"],
      ["SDI"],
      RangeTemplate.Sdi,
      templatesOptions
    );

    addTemplate(
      ["Pavement Condition Index", " PCI ", "(PCI)"],
      ["PCI"],
      RangeTemplate.Pci,
      templatesOptions
    );

    addTemplate(
      ["Structural Index", " SI ", "(SI)"],
      ["SI"],
      RangeTemplate.Si,
      templatesOptions
    );

    addTemplate(
      ["Roughness Index", " RI ", "(RI)"],
      ["RI"],
      RangeTemplate.Ri,
      templatesOptions
    );

    addTemplate(
      ["Roughness Index", " RI ", "(RI)"],
      ["RI"],
      RangeTemplate.Ri,
      templatesOptions
    );

    addTemplate(["PSCI"], ["PSCI"], RangeTemplate.Pcsi, templatesOptions);

    addTemplate(["PSCPRI"], ["PSCPRI"], RangeTemplate.Pscpri, templatesOptions);

    // if (!tableColumn.unitMeasure || tableColumn.unitMeasure === Unit.None) {
    //   templateOption = new DropdownOption<RangeTemplate>();
    //   templateOption.label = RangeTemplate.Percentage;
    //   templateOption.value = RangeTemplate.Percentage;
    //   templatesOptions.push(templateOption);
    // } else if (
    //   tableColumn.unitMeasure === Unit.MeterPerKilometer ||
    //   tableColumn.unitMeasure === Unit.InchPerMile ||
    //   tableColumn.unitMeasure === Unit.MillimeterPerMeter
    // ) {
    //   templateOption = new DropdownOption<RangeTemplate>();
    //   templateOption.label = RangeTemplate.Iri;
    //   templateOption.value = RangeTemplate.Iri;
    //   templatesOptions.push(templateOption);
    // }

    setTemplateOptions(templatesOptions);

    // setSelectedTemplateOption(
    //   templatesOptions.find((t) => t.value === RangeTemplate.Percentage)
    // );

    setSelectedTemplateOption(templatesOptions.at(0));
  }, [tableColumn]);

  const onGenerateValues = () => {
    let newRanges: ThematicMapLayerRange[] = [];

    const templateGenerator = new ThematicRangesTemplateGenerator();

    if (selectedTemplateOption?.value) {
      switch (selectedTemplateOption.value) {
        case RangeTemplate.Iri:
          newRanges = templateGenerator.generateIriTemplateRanges(tableColumn);
          break;
        case RangeTemplate.Sdi:
          newRanges = templateGenerator.generateSdiTemplateRanges();
          break;
        case RangeTemplate.Pci:
          newRanges = templateGenerator.generatePciTemplateRanges();
          break;
        case RangeTemplate.Si:
          newRanges = templateGenerator.generateSiTemplateRanges();
          break;
        case RangeTemplate.Ri:
          newRanges = templateGenerator.generateRiTemplateRanges();
          break;
        case RangeTemplate.Pcsi:
          newRanges = templateGenerator.generatePcsiTemplateRanges();
          break;
        case RangeTemplate.Pscpri:
          newRanges = templateGenerator.generatePscpriTemplateRanges();
          break;

        default:
          break;
      }
    }

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onLabelChanged = (event: any, range: ThematicMapLayerRange) => {
    const newValue = event.target.value;
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeLabel)]: newValue,
    });
  };

  const onHasRangeStartChanged = (event: any, range: ThematicMapLayerRange) => {
    const checked = event.target.checked;
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.hasRangeStart)]: checked,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeStartValue)]: 0,
    });
  };

  const onHasRangeEndChanged = (event: any, range: ThematicMapLayerRange) => {
    const checked = event.target.checked;
    setNewRanges(range, {
      hasRangeEnd: checked,
      rangeEndValue: 0,
    });
  };

  const onRangeStartValueChanged = (
    event: any,
    range: ThematicMapLayerRange
  ) => {
    const newValue = Number(event.target.value);
    let newValueSi = unitConverter.convertValueToBaseUnit(
      newValue,
      tableColumn.unitMeasure
    );

    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeStartDisplayValue)]:
        newValue,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeStartValue)]: newValueSi,
    });
  };

  const onRangeEndValueChanged = (event: any, range: ThematicMapLayerRange) => {
    const newValue = Number(event.target.value);
    const newValueSi = unitConverter.convertValueToBaseUnit(
      newValue,
      tableColumn.unitMeasure
    );

    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeEndDisplayValue)]: newValue,
      [nameOf<ThematicMapLayerRange>((p) => p.rangeEndValue)]: newValueSi,
    });
  };

  const onRangeColorChanged = (color: string, range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.rangeColor)]: color,
    });
  };

  const onRangeShowColorPickerChanged = (range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.showColorPicker)]:
        !range.showColorPicker,
    });
  };

  const onRangeColorPickerClickOutside = (range: ThematicMapLayerRange) => {
    setNewRanges(range, {
      [nameOf<ThematicMapLayerRange>((p) => p.showColorPicker)]: false,
    });
  };

  const setNewRanges = (range: ThematicMapLayerRange, props: any) => {
    const newRanges = thematicMapLayerRanges.map((r) =>
      r === range ? { ...r, ...props } : { ...r }
    );

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onNewRange = () => {
    const newRange = new ThematicMapLayerRange();
    newRange.rangeColor = colorHelper.getRandomColor();
    newRange.hasRangeStart = true;
    newRange.hasRangeEnd = true;

    const indexes: number[] = thematicMapLayerRanges.map((r) => r.index);
    newRange.index = indexes.length > 0 ? Math.max(...indexes) + 1 : 1;

    const newRanges = [...thematicMapLayerRanges];
    newRanges.push(newRange);

    onThematicMapLayerRangesChanged(newRanges);
  };

  const onDeleteRange = (range: ThematicMapLayerRange) => {
    const newRanges = thematicMapLayerRanges.filter((r) => r !== range);
    onThematicMapLayerRangesChanged(newRanges);
  };

  const onTemplateOptionChaged = async (
    newValue: DropdownOption<RangeTemplate>
  ) => {
    setSelectedTemplateOption(newValue);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <div
          className="image-button-link d-flex align-items-center"
          onClick={onNewRange}
        >
          <AddIcon />
          <div className="image-button-link-text">Add</div>
        </div>
        {templatesOptions.length > 0 && (
          <>
            <div className="ms-3">Templates</div>
            <div className="ms-1">
              <Dropdown
                minWidth="200px"
                options={templatesOptions}
                value={selectedTemplateOption}
                onChange={(newValue: any) => onTemplateOptionChaged(newValue)}
              />
            </div>
            <div className="ms-1">
              <button
                className="btn btn-primary btn-sm"
                style={{ textWrap: "nowrap" }}
                type="button"
                onClick={onGenerateValues}
              >
                Generate Ranges
              </button>
            </div>
          </>
        )}
      </div>
      {thematicMapLayerRanges.length > 0 && (
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Label</th>
              <th scope="col"></th>
              <th scope="col">Range Start</th>
              <th scope="col"></th>
              <th scope="col">&lt;= Range End</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {thematicMapLayerRanges.map(
              (range: ThematicMapLayerRange, index) => (
                <tr key={index.toString()}>
                  <td>
                    <div style={{ marginTop: "4px", marginRight: "4px" }}>
                      <div
                        style={{
                          padding: "5px",
                          background: "#fff",
                          borderRadius: "1px",
                          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            background: range.rangeColor,
                          }}
                          onClick={() => onRangeShowColorPickerChanged(range)}
                        ></div>
                      </div>
                      {range.showColorPicker && (
                        <ColorPicker
                          color={range.rangeColor}
                          onColorChange={(color: any) =>
                            onRangeColorChanged(color.hex, range)
                          }
                          onClickOutside={() =>
                            onRangeColorPickerClickOutside(range)
                          }
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={range.rangeLabel}
                      onChange={(event) => onLabelChanged(event, range)}
                    />
                  </td>
                  <td align="right">
                    <input
                      type="checkbox"
                      tabIndex={-1}
                      className="form-check-input"
                      checked={range.hasRangeStart}
                      onChange={(event) => onHasRangeStartChanged(event, range)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "100px" }}
                      // value={
                      //   range.hasRangeStart
                      //     ? unitConverter.convertValue(
                      //         range.rangeStartValue,
                      //         tableColumn.unitMeasure
                      //       )
                      //     : ""
                      // }
                      value={
                        range.hasRangeStart ? range.rangeStartDisplayValue : ""
                      }
                      onChange={(event) =>
                        onRangeStartValueChanged(event, range)
                      }
                      step="0.001"
                      disabled={!range.hasRangeStart}
                    />
                  </td>
                  <td align="right">
                    <input
                      type="checkbox"
                      tabIndex={-1}
                      className="form-check-input"
                      checked={range.hasRangeEnd}
                      onChange={(event) => onHasRangeEndChanged(event, range)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "100px" }}
                      // value={
                      //   range.hasRangeEnd
                      //     ? unitConverter.convertValue(
                      //         range.rangeEndValue,
                      //         tableColumn.unitMeasure
                      //       )
                      //     : ""
                      // }
                      value={
                        range.hasRangeEnd ? range.rangeEndDisplayValue : ""
                      }
                      onChange={(event) => onRangeEndValueChanged(event, range)}
                      step="0.001"
                      disabled={!range.hasRangeEnd}
                    />
                  </td>
                  <td>
                    <div
                      className="image-button-link"
                      onClick={() => onDeleteRange(range)}
                    >
                      <ClearIcon />
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ThematicRangesEditor;
