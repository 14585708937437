const replaceConsecutiveSpaces = (text: string): string => {
  return text.replace(/\s\s+/g, " ");
};

const stringContainsIgnoreCase = (inputString: string, contains: string) => {
  const isContains =
    inputString.toLowerCase().indexOf(contains.toLowerCase()) !== -1;
  return isContains;
};

const stringHelper = {
  replaceConsecutiveSpaces,
  stringContainsIgnoreCase,
};

export default stringHelper;
