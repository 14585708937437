const useProd: boolean = true;

const appVersion: string = "1.3.0.117";

const serverUrlProd = "https://inform.internationalcybernetics.com";
const serverUrlDev = "http://localhost:5000";

const serverUrl = useProd ? serverUrlProd : serverUrlDev;

const appSettings = {
  useProd,
  serverUrl,
  appVersion,
};

export default appSettings;
