import { Layout } from "react-grid-layout";
import workspaceService from "../services/workspaceService";
import screenHelper from "../util/screenHelper";
import ImageStream from "./imageStream";
import WindowIds from "./layout/windowIds";
import WindowVisibility from "./layout/windowVisibility";
import Workspace from "./workspace";
import WorkspaceLayout from "./workspaceLayout";
import User from "./user";

export default class WorkspaceManager {
  public layout: Layout[] = [];
  public layoutInvisibleItems: Layout[] = [];
  public windowVisibilities: WindowVisibility[] = [];
  public workspace: Workspace = new Workspace();

  private user: User = new User();

  public clear = () => {
    this.layout = [];
    this.layoutInvisibleItems = [];
    this.windowVisibilities = [];
    this.workspace = new Workspace();
  };

  private getDefaultLayoutPanoFwPaveMapTable = (
    cameras: ImageStream[]
  ): WorkspaceLayout => {
    const defaultVisible: Layout[] = [];
    const defaultInvisible: Layout[] = [];

    const forwardCamera = cameras.find((c) =>
      c.cameraName.toLowerCase().includes("forward")
    );

    //const panoCamera: any = undefined;
    const panoCamera = cameras.find((c) => c.isPanoramic);
    if (panoCamera) {
      const panoCameraId = WindowIds.getCameraId(panoCamera.cameraName);
      defaultVisible.push({ x: 0, y: 0, w: 10, h: 2, i: panoCameraId });
    }

    if (forwardCamera) {
      const forwardCameraId = WindowIds.getCameraId(forwardCamera.cameraName);
      if (panoCamera) {
        defaultVisible.push({ x: 0, y: 3, w: 10, h: 3, i: forwardCameraId });
      } else {
        defaultVisible.push({ x: 0, y: 0, w: 10, h: 5, i: forwardCameraId });
      }
    }

    const pavementCamera = cameras.find((c) => c.isPavement);
    if (pavementCamera) {
      const pavementCameraId = WindowIds.getCameraId(pavementCamera.cameraName);
      defaultVisible.push({ x: 10, y: 0, w: 2, h: 5, i: pavementCameraId });
    }

    defaultVisible.push({ x: 0, y: 5, w: 6, h: 3, i: WindowIds.MapId });
    defaultVisible.push({ x: 6, y: 5, w: 6, h: 3, i: WindowIds.TableId });

    const allWindowsIds = this.getAllWindowIds(cameras);

    let newIndex = 0;
    for (const windowId of allWindowsIds) {
      const inInDefaultLayout = defaultVisible.find((i) => i.i === windowId);
      if (inInDefaultLayout) {
        continue;
      }

      defaultInvisible.push({ x: newIndex, y: 0, w: 2, h: 2, i: windowId });
      newIndex += 2;
    }

    const layout = new WorkspaceLayout();
    layout.layout = defaultVisible;
    layout.layoutInvisibleItems = defaultInvisible;

    return layout;
  };

  private getDefaultLayoutFwMap = (cameras: ImageStream[]): WorkspaceLayout => {
    const defaultVisible: Layout[] = [];
    const defaultInvisible: Layout[] = [];

    let fwH: number;
    let mapH: number;

    if (screenHelper.is4k()) {
      fwH = 5;
      mapH = 3;
    } else {
      fwH = 4; // 4 * gridColsCount = 4 * 100 = 400;
      mapH = 2; // 2 * 100 = 200;
    }

    const forwardCamera = cameras.find((c) =>
      c.cameraName.toLowerCase().includes("forward")
    );

    if (forwardCamera) {
      const forwardCameraId = WindowIds.getCameraId(forwardCamera.cameraName);
      defaultVisible.push({ x: 0, y: 0, w: 12, h: fwH, i: forwardCameraId });
    }

    defaultVisible.push({ x: 0, y: 5, w: 12, h: mapH, i: WindowIds.MapId });

    const allWindowsIds = this.getAllWindowIds(cameras);

    let newIndex = 0;
    for (const windowId of allWindowsIds) {
      const inInDefaultLayout = defaultVisible.find((i) => i.i === windowId);
      if (inInDefaultLayout) {
        continue;
      }

      defaultInvisible.push({ x: newIndex, y: 0, w: 2, h: 2, i: windowId });
      newIndex += 2;
    }

    const layout = new WorkspaceLayout();
    layout.layout = defaultVisible;
    layout.layoutInvisibleItems = defaultInvisible;

    return layout;
  };

  private getDefaultLayoutFwPavePanoMap = (
    cameras: ImageStream[]
  ): WorkspaceLayout => {
    const defaultVisible: Layout[] = [];
    const defaultInvisible: Layout[] = [];

    let fwH: number;
    let mapH: number;

    if (screenHelper.is4k()) {
      fwH = 5;
      mapH = 3;
    } else {
      // this is based on Home.tsx setGridRowHeight(97) with chrome bookmarks visible as AZ had it
      // setGridRowHeight(103) would be without chrome bookmarks bar
      fwH = 4; // 4 * gridColsCount = 4 * 100 = 400;
      mapH = 2; // 2 * 100 = 200;
    }

    // first row - y = 0

    const forwardCamera = cameras.find((c) =>
      c.cameraName.toLowerCase().includes("forward")
    );

    const pavementCamera = cameras.find((c) => c.isPavement);

    const fwW = 10;

    if (forwardCamera) {
      const forwardCameraId = WindowIds.getCameraId(forwardCamera.cameraName);
      defaultVisible.push({ x: 0, y: 0, w: fwW, h: fwH, i: forwardCameraId });
    }

    if (pavementCamera) {
      const pavementCameraId = WindowIds.getCameraId(pavementCamera.cameraName);
      defaultVisible.push({ x: fwW, y: 0, w: 2, h: fwH, i: pavementCameraId });
    }

    // second row

    const panoCamera = cameras.find((c) => c.isPanoramic);

    let mapW = 6;

    if (!panoCamera) {
      mapW = 12;
    }

    defaultVisible.push({ x: 0, y: fwH, w: mapW, h: mapH, i: WindowIds.MapId });

    if (panoCamera) {
      const panoCameraId = WindowIds.getCameraId(panoCamera.cameraName);
      defaultVisible.push({ x: mapW, y: fwH, w: 6, h: mapH, i: panoCameraId });
    }

    const allWindowsIds = this.getAllWindowIds(cameras);

    let newIndex = 0;
    for (const windowId of allWindowsIds) {
      const inInDefaultLayout = defaultVisible.find((i) => i.i === windowId);
      if (inInDefaultLayout) {
        continue;
      }

      defaultInvisible.push({ x: newIndex, y: 0, w: 2, h: 2, i: windowId });
      newIndex += 2;
    }

    const layout = new WorkspaceLayout();
    layout.layout = defaultVisible;
    layout.layoutInvisibleItems = defaultInvisible;

    return layout;
  };

  private getDefaultLayoutFwRightPavePanoMap = (
    cameras: ImageStream[]
  ): WorkspaceLayout => {
    const defaultVisible: Layout[] = [];
    const defaultInvisible: Layout[] = [];

    let fwH: number;
    let rH: number;
    let mapH: number;

    if (screenHelper.is4k()) {
      fwH = 5;
      rH = 2;
      mapH = 3;
    } else {
      // this is based on Home.tsx setGridRowHeight(97) with chrome bookmarks visible as AZ had it
      // setGridRowHeight(103) would be without chrome bookmarks bar
      fwH = 4; // 4 * gridColsCount = 4 * 100 = 400;
      rH = 1;
      mapH = 2; // 2 * 100 = 200;
    }

    // first row - y = 0

    const forwardCamera = cameras.find((c) =>
      c.cameraName.toLowerCase().includes("forward")
    );

    const rightCamera = cameras.find(
      (c) =>
        c.cameraName.toLowerCase().includes("right") &&
        !c.cameraName.toLowerCase().includes("rear")
    );

    const pavementCamera = cameras.find((c) => c.isPavement);

    const fwW = 10;

    if (forwardCamera) {
      const forwardCameraId = WindowIds.getCameraId(forwardCamera.cameraName);
      defaultVisible.push({ x: 0, y: 0, w: fwW, h: fwH, i: forwardCameraId });
    }

    if (rightCamera) {
      const rightCameraId = WindowIds.getCameraId(rightCamera.cameraName);
      defaultVisible.push({ x: fwW, y: 0, w: 2, h: rH, i: rightCameraId });
    } else {
      rH = 0;
    }

    if (pavementCamera) {
      const pavementCameraId = WindowIds.getCameraId(pavementCamera.cameraName);
      defaultVisible.push({
        x: fwW,
        y: rH,
        w: 2,
        h: fwH - rH,
        i: pavementCameraId,
      });
    }

    // second row

    const panoCamera = cameras.find((c) => c.isPanoramic);

    let mapW = 6;

    if (!panoCamera) {
      mapW = 12;
    }

    defaultVisible.push({ x: 0, y: fwH, w: mapW, h: mapH, i: WindowIds.MapId });

    if (panoCamera) {
      const panoCameraId = WindowIds.getCameraId(panoCamera.cameraName);
      defaultVisible.push({ x: mapW, y: fwH, w: 6, h: mapH, i: panoCameraId });
    }

    const allWindowsIds = this.getAllWindowIds(cameras);

    let newIndex = 0;
    for (const windowId of allWindowsIds) {
      const inInDefaultLayout = defaultVisible.find((i) => i.i === windowId);
      if (inInDefaultLayout) {
        continue;
      }

      defaultInvisible.push({ x: newIndex, y: 0, w: 2, h: 2, i: windowId });
      newIndex += 2;
    }

    const layout = new WorkspaceLayout();
    layout.layout = defaultVisible;
    layout.layoutInvisibleItems = defaultInvisible;

    return layout;
  };

  private getDefaultLayout = (cameras: ImageStream[]): WorkspaceLayout => {
    return this.getDefaultLayoutFwRightPavePanoMap(cameras);
  };

  private getAllWindowIds(cameras: ImageStream[]): string[] {
    const windowIds: string[] = [];

    for (const camera of cameras) {
      windowIds.push(WindowIds.getCameraId(camera.cameraName));
    }

    windowIds.push(WindowIds.MapId);

    windowIds.push(WindowIds.TableId);

    return windowIds;
  }

  public setUser = (user: User) => {
    this.user = user;
  };

  private createDefaultWorkspace(cameras: ImageStream[], idProject: number) {
    const defaultWorkspace = new Workspace();
    defaultWorkspace.idProject = idProject;
    defaultWorkspace.idUser = this.user.idUser;
    defaultWorkspace.workspaceName = "My Workspace";
    defaultWorkspace.workspaceLayout = this.getDefaultLayout(cameras);
    defaultWorkspace.setLayoutFromWorkspaceLayout();
    return defaultWorkspace;
  }

  public async initWorkspace(cameras: ImageStream[], idProject: number) {
    const defaultWorkspace = this.createDefaultWorkspace(cameras, idProject);
    const workspace = await workspaceService.getWorkspace(defaultWorkspace);

    this.workspace = workspace;
    let layout = workspace.workspaceLayout;

    this.initLayout(layout, cameras);
  }

  private initLayout(layout: WorkspaceLayout, cameras: ImageStream[]) {
    // add windows not in layout - this practically should never happen (fail-safe)
    const allWindowsIds = this.getAllWindowIds(cameras);
    for (const windowId of allWindowsIds) {
      const inSaveLayout = layout.layout.find((i) => i.i === windowId);
      if (inSaveLayout) {
        continue;
      }
      const inInvisibleLayoutItems = layout.layoutInvisibleItems.find(
        (i) => i.i === windowId
      );
      if (inInvisibleLayoutItems) {
        continue;
      }
      // not in any layout (visible or invisible), add it as visible
      const defaultLayout = { x: 0, y: 0, w: 2, h: 2, i: windowId };
      layout.layout.push(defaultLayout);
    }

    const windowVisibilities: WindowVisibility[] = [];
    for (const l of layout.layout) {
      windowVisibilities.push(new WindowVisibility(l.i, true));
    }
    for (const l of layout.layoutInvisibleItems) {
      windowVisibilities.push(new WindowVisibility(l.i, false));
    }

    this.layout = layout.layout;
    this.layoutInvisibleItems = layout.layoutInvisibleItems;
    this.windowVisibilities = windowVisibilities;
  }

  public async resetWorkspace(cameras: ImageStream[], idProject: number) {
    const defaultWorkspace = this.createDefaultWorkspace(cameras, idProject);

    let workspace = await workspaceService.getProjectDefaultWorkspace(
      idProject
    );

    if (workspace) {
      workspace.idWorkspace = this.workspace.idWorkspace;
    } else {
      workspace = defaultWorkspace;
      workspace.idWorkspace = this.workspace.idWorkspace;
    }

    this.workspace = workspace;
    let layout = workspace.workspaceLayout;

    this.initLayout(layout, cameras);
  }

  public setLayout(layout: WorkspaceLayout, cameras: ImageStream[]) {
    this.initLayout(layout, cameras);
  }

  public onWindowVisibilityChanged(windowId: string, isVisible: boolean) {
    let newLayout: Layout[] = [];
    let newLayoutInvisibleItems: Layout[] = [];

    if (!isVisible) {
      const closedItem = this.layout.find((l) => l.i === windowId);
      if (closedItem) {
        newLayoutInvisibleItems = [...this.layoutInvisibleItems];
        newLayoutInvisibleItems.push(closedItem);
        this.layoutInvisibleItems = newLayoutInvisibleItems;
      }

      newLayout = this.layout.filter((l) => l.i !== windowId);
      this.layout = newLayout;
    } else {
      const itemMadeVisibleLayout = this.layoutInvisibleItems.find(
        (l) => l.i === windowId
      );
      if (itemMadeVisibleLayout) {
        const exitingItemOnSamePosition = this.layout.find(
          (i) =>
            i.x === itemMadeVisibleLayout.x &&
            i.y === itemMadeVisibleLayout.y &&
            i.w === itemMadeVisibleLayout.w
        );

        if (exitingItemOnSamePosition) {
          for (const layoutItem of this.layout) {
            if (layoutItem.i === exitingItemOnSamePosition.i) {
              newLayout.push(itemMadeVisibleLayout);
              layoutItem.y++;
              newLayout.push(layoutItem);
            } else {
              newLayout.push(layoutItem);
            }
          }
        } else {
          newLayout = [...this.layout];
          newLayout.push(itemMadeVisibleLayout);
        }

        this.layout = newLayout;
      }

      newLayoutInvisibleItems = this.layoutInvisibleItems.filter(
        (l) => l.i !== windowId
      );
      this.layoutInvisibleItems = newLayoutInvisibleItems;
    }

    const newWindowVisibilities = this.windowVisibilities.filter(
      (wv) => wv.windowId !== windowId
    );
    newWindowVisibilities.push(new WindowVisibility(windowId, isVisible));
    this.windowVisibilities = newWindowVisibilities;

    this.saveWorkspace(newLayout, newLayoutInvisibleItems);
  }

  private async saveWorkspace(
    layout: Layout[],
    layoutInvisibleItems: Layout[]
  ) {
    if (this.user.isDemoUser) {
      return;
    }

    this.workspace.workspaceLayout.layout = layout;
    this.workspace.workspaceLayout.layoutInvisibleItems = layoutInvisibleItems;
    this.workspace.setLayoutFromWorkspaceLayout();

    await workspaceService.saveWorkspace(this.workspace);
  }

  public onLayoutChanged(layout: Layout[]) {
    this.layout = layout;

    this.saveWorkspace(layout, this.layoutInvisibleItems);
  }
}
