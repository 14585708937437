import "pannellum/src/js/pannellum";
import "pannellum/src/js/libpannellum";
import "pannellum/src/css/pannellum.css";

import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ImageFrame from "../../models/imageFrame";
import ImagePreloader from "../../models/imagePreloader";
import Navigable, { NavigatorMove } from "../../models/navigable";
import Navigator from "../../models/navigator";
import NavigatorPosition from "../../models/navigatorPosition";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import imageService from "../../services/imageService";
import ImageCacheItem from "../../models/imageCacheItem";
import ProjectInfo from "../../models/projectInfo";
import ImageNotAvailableImage from "../../assets/images/image-not-available-wide.png";
import ImageNotAvailableImageEmptyWhite from "../../assets/images/image-not-available-empty-white.png";

const logService = new LogService(LogDirectory.EnableCameraLogging);

declare var pannellum: any;

export interface CameraPanoRef {
  refresh: () => void;
}

interface Props {
  cameraName: string;
  navigator: Navigator;
  project: ProjectInfo;
  //children: React.ReactNode;
}

const CameraPano = forwardRef((props: Props, ref: Ref<CameraPanoRef>) => {
  const { cameraName, navigator, project } = props;

  const [imageNotAvailable, setImageNotAvailable] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({ refresh }));

  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    const createViewer = () => {
      let _viewer = pannellum.viewer("panorama", {
        default: {
          firstScene: "imageId",
          hfov: 120,
          author: refreshCount.toString(),
        },
        scenes: {
          sceneId: {},
        },
      });
      return _viewer;
    };

    let viewer = createViewer();
    let canvas: any;

    // TODO: this is also in CameraPavement - move this to single place later
    const loadImage = (src: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = (event: any) => resolve(event.target);
        //img.onerror = reject;
        // img.onerror = (ex) => {
        //   console.log("err", ex);
        // };
        img.src = src;
      });
    };

    viewer.on("fullscreenchange", async (fullscreenActive: boolean) => {
      // console.log(
      //   "penelum-fullscreenchange-fullscreenActive",
      //   fullscreenActive
      // );
      if (fullscreenActive) {
        if (currentBatchImageFrameIndex !== -1) {
          const currentImageFrame =
            currentBatchImageFrames[currentBatchImageFrameIndex];

          const fullSizeImage = await loadImage(
            currentImageFrame.imageFullSizeUrl
          );
          loadSceneImage(fullSizeImage.src);
        }
      }
    });

    logService.log(`camera[${cameraName}]:init()`);

    const imagePreloader: ImagePreloader = new ImagePreloader();

    let currentIdSession: number | null = null;
    let currentBatchImageFrames: ImageFrame[] = [];
    let currentBatchImageFrameIndex: number = -1;

    const navigable = new Navigable();
    navigable.id = `camera[${cameraName}]`;

    let noImages: boolean = false;

    const loadData = async (navigatorPosition: NavigatorPosition) => {
      logService.log(`camera[${cameraName}]:loadData()`);
      try {
        const { data: sessionImageFrameIndex } =
          await imageService.getImageFrameIndex(
            project.idProject,
            navigatorPosition.idSession,
            navigatorPosition.distance,
            cameraName
          );

        let startIndex =
          sessionImageFrameIndex - imageService.preloaderBackCacheSize * 10;
        if (startIndex < 0) {
          startIndex = 0;
        }
        const stopIndex = startIndex + imageService.batchSize;

        const { data: frames } = await imageService.getImageFrames(
          project.idProject,
          navigatorPosition.idSession,
          cameraName,
          startIndex,
          stopIndex
        );

        currentBatchImageFrames = frames;

        currentBatchImageFrameIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchImageFrames
          );

        setImageNotAvailable(false);

        noImages = frames.length === 0;

        if (noImages) {
          setImageNotAvailable(true);

          viewer.addScene("imageId", {
            panorama: ImageNotAvailableImageEmptyWhite,
            autoLoad: true,
          });
          viewer.loadScene("imageId");

          const renderer = viewer.getRenderer();
          if (renderer) {
            canvas = renderer.getCanvas();
            removeWebglContextHooks();
            addWebglContextHooks();
          }

          return;
        }

        var imageUrls = [];
        for (const frame of frames) {
          imageUrls.push(frame.imageUrl);
        }

        imagePreloader.setCameraName(cameraName);
        imagePreloader.setImageUrls(imageUrls);

        logService.log(`camera[${cameraName}]:loadData()-done`);
      } catch (error) {
        logService.log(error);
      }
    };

    const loadSceneImage = (imageUrl: string) => {
      //setImageUrl(imageUrl);
      viewer.addScene("imageId", {
        type: "equirectangular",
        panorama: `${imageUrl}`,
        autoLoad: true,
        hfov: viewer.getHfov(),
        yaw: viewer.getYaw(),
        pitch: viewer.getPitch(),
      });
      viewer.loadScene("imageId");

      const renderer = viewer.getRenderer();
      if (renderer) {
        canvas = renderer.getCanvas();
        removeWebglContextHooks();
        addWebglContextHooks();
      }
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log(`camera[${cameraName}]:navigator:move()`);

      if (currentIdSession === navigatorPosition.idSession) {
        currentBatchImageFrameIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchImageFrames
          );
      } else {
        currentBatchImageFrameIndex = -1;
        currentIdSession = navigatorPosition.idSession;
        noImages = false;
      }

      if (currentBatchImageFrameIndex === -1 && !noImages) {
        await loadData(navigatorPosition);
      }

      if (noImages) {
        return;
      }

      const imageUrl = await imagePreloader.getImage(
        currentBatchImageFrameIndex
      );
      if (imageUrl && imageUrl !== ImageCacheItem.LOADING) {
        logService.log(`camera[${cameraName}]:navigator:imageUrl`, imageUrl);
        loadSceneImage(imageUrl);
      }

      logService.log(`camera[${cameraName}]:navigator:move()-done`);
    };
    navigable.navigatorMove = navigatorMove;

    const addWebglContextHooks = () => {
      if (!canvas) {
        return;
      }

      canvas.addEventListener("webglcontextlost", onWebglContextLost, false);

      canvas.addEventListener(
        "webglcontextrestored",
        onWebglContextRestored,
        false
      );
    };

    const removeWebglContextHooks = () => {
      if (!canvas) {
        return;
      }

      canvas.removeEventListener("webglcontextlost", onWebglContextLost, false);

      canvas.removeEventListener(
        "webglcontextrestored",
        onWebglContextRestored,
        false
      );
    };

    const onWebglContextLost = (event: any) => {
      console.log("Pano Camera Webgl Context Lost");
      event.preventDefault();
    };

    const onWebglContextRestored = (event: any) => {
      console.log("Pano Camera Webgl Context Restored");
      event.preventDefault();

      viewer.destroy();
      viewer = createViewer();

      // refresh
      if (navigator.navigatorPosition) {
        navigatorMove(navigator.navigatorPosition);
      }
    };

    navigator.attach(navigable);

    // this sould be called on open/close window
    if (
      navigator.navigatorPosition &&
      navigator.navigatorPosition.idProject === project.idProject
    ) {
      logService.log(`camera[${cameraName}]:navigator:move()-init`);
      navigatorMove(navigator.navigatorPosition);
    }

    return () => {
      navigator.detach(navigable);
      imagePreloader.clearCache();
      removeWebglContextHooks();
      viewer.destroy();
    };
  }, [cameraName, navigator, refreshCount, project]);

  const refresh = () => {
    setRefreshCount((prev) => prev + 1);
    logService.log("panoCamRefresh()");
  };

  return (
    <>
      <div id="panorama"></div>
      {imageNotAvailable && (
        <img
          className="camera-img"
          style={{ position: "absolute", top: "0", right: "0" }}
          src={ImageNotAvailableImage}
          alt=""
        />
      )}
    </>
  );
});

export default CameraPano;
