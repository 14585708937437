import { useContext, useState } from "react";
import ThematicMapLayerEdit from "./thematic-maps/ThematicMapLayerEdit";
import ProjectInfo from "../../models/projectInfo";
import TableColum from "../../models/tableColumn";
import MapLayer from "../../models/mapLayer";
import MapLayerType from "../../models/mapLayerType";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";
import ThematicMapLayer from "../../models/thematicMapLayer";
import { Tooltip } from "@mui/material";
import MapLayerShareIcon from "./MapLayerShareIcon";

const MapLayers: React.FC<{
  project: ProjectInfo;
  mapLayers: MapLayer[];
  tableColumns: TableColum[];
  onCreateMapLayer: any;
  onUpdateMapLayer: any;
  onDeleteMapLayer: any;
  onUpdateMapLayerVisibility: any;
}> = ({
  project,
  mapLayers,
  tableColumns,
  onUpdateMapLayer,
  onCreateMapLayer,
  onDeleteMapLayer,
  onUpdateMapLayerVisibility,
}) => {
  const networkMapLayer = mapLayers.find(
    (ml) => ml.mapLayerType === MapLayerType.NetworkLayer
  );

  const thematicMapLayers = mapLayers.filter(
    (ml) => ml.mapLayerType === MapLayerType.ThematicLayer
  );

  const user = useContext<User | undefined>(UserContext);

  const [showEditMapLayer, setShowEditMapLayer] = useState<boolean>(false);

  const [mapLayer, setMapLayer] = useState<MapLayer>(new MapLayer());

  const onCreateMapLayerProxy = async (mapLayer: MapLayer) => {
    setShowEditMapLayer(false);
    await onCreateMapLayer(mapLayer);
  };

  const onUpdateMapLayerProxy = async (mapLayer: MapLayer) => {
    setShowEditMapLayer(false);
    await onUpdateMapLayer(mapLayer);
  };

  const onDeleteMapLayerProxy = async (mapLayer: MapLayer) => {
    setShowEditMapLayer(false);
    await onDeleteMapLayer(mapLayer);
  };

  const onCancel = () => {
    setShowEditMapLayer(false);
  };

  const onNewThematicMapLayer = () => {
    const newMapLayer = new MapLayer();
    newMapLayer.idProject = project.idProject;

    newMapLayer.isVisible = true;

    const indexes: number[] = mapLayers.map((ml) => ml.index);
    newMapLayer.index = Math.max(...indexes) + 1;

    if (user) {
      newMapLayer.idUser = user.idUser;
    }

    newMapLayer.thematicMapLayer = new ThematicMapLayer();

    setMapLayer(newMapLayer);

    setShowEditMapLayer(true);
  };

  const onEditThematicMapLayer = (event: any, mapLayer: MapLayer) => {
    event.stopPropagation();
    setMapLayer(mapLayer);

    setShowEditMapLayer(true);
  };

  const onLayerClick = async (event: any, mapLayer: MapLayer) => {
    await onUpdateMapLayerVisibility(mapLayer, !mapLayer.isVisible);
  };

  return (
    <div className={showEditMapLayer ? "d-flex" : ""}>
      <div
        style={{
          padding: "10px",
          background: "white",
        }}
      >
        <ul className="list-group" style={{ minWidth: "200px" }}>
          {networkMapLayer && (
            <li
              className={
                "list-group-item list-group-item-action" +
                (networkMapLayer.isVisible ? " active" : "")
              }
              style={{ cursor: "pointer" }}
              onClick={(e) => onLayerClick(e, networkMapLayer)}
            >
              <div className="d-flex justify-content-between">
                <div>{networkMapLayer.layerName}</div>
              </div>
            </li>
          )}
          {thematicMapLayers.map((mapLayer, index) => (
            <li
              className={
                "list-group-item list-group-item-action" +
                (mapLayer.isVisible ? " active" : "")
              }
              style={{ cursor: "pointer" }}
              key={index.toString()}
              onClick={(e) => onLayerClick(e, mapLayer)}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div>{mapLayer.layerName}</div>
                  <MapLayerShareIcon mapLayer={mapLayer} />
                </div>
                <div
                  style={{ marginLeft: "15px" }}
                  onClick={(event) => onEditThematicMapLayer(event, mapLayer)}
                >
                  <Tooltip
                    className="ms-2 align-self-center"
                    title="Layer Settings"
                  >
                    <i className="navbar-nav-fa fa fa-cog fa-lg"></i>
                  </Tooltip>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <button
          type="button"
          className="btn btn-sm btn-primary mt-3"
          onClick={onNewThematicMapLayer}
        >
          New Layer
        </button>
      </div>
      {showEditMapLayer && (
        <div
          style={{
            borderWidth: "0px 0px 0px 3px",
            borderStyle: "solid",
            borderImage: "linear-gradient(white, #808080cc, white) 1 100%",
            // marginLeft: "16px",
            // border: "1px solid red",
          }}
        >
          <ThematicMapLayerEdit
            project={project}
            tableColumns={tableColumns}
            mapLayer={
              user?.isPortalAdmin &&
              mapLayer.parentMapLayer &&
              mapLayer.parentMapLayer.isDefault
                ? mapLayer.parentMapLayer
                : mapLayer
            }
            isNew={mapLayer.idMapLayer === 0}
            onCreateMapLayer={onCreateMapLayerProxy}
            onUpdateMapLayer={onUpdateMapLayerProxy}
            onDeleteMapLayer={onDeleteMapLayerProxy}
            onCancel={onCancel}
          />
        </div>
      )}
    </div>
  );
};

export default MapLayers;
