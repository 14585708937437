import MapLayer from "../../models/mapLayer";
import ShareIcon from "@mui/icons-material/Share";
import LockIcon from "@mui/icons-material/Lock";
import { Tooltip } from "@mui/material";

const MapLayerShareIcon: React.FC<{ mapLayer: MapLayer }> = ({ mapLayer }) => {
  return (
    <>
      {(mapLayer.parentMapLayer?.isDefault || mapLayer.isDefault) && (
        <Tooltip
          className="ms-2"
          title={
            mapLayer.isSharedDataChanged
              ? "Default Layer Changed, reset to default"
              : "Default Layer"
          }
        >
          <LockIcon
            style={{
              fontSize: "14px",
              marginTop: "2px",
              color: mapLayer.isSharedDataChanged ? "orange" : "initial",
            }}
          />
        </Tooltip>
      )}
      {(mapLayer.parentMapLayer?.isShared || mapLayer.isShared) &&
        !(mapLayer.parentMapLayer?.isDefault || mapLayer.isDefault) && (
          <Tooltip
            className="ms-2"
            title={
              mapLayer.isSharedDataChanged
                ? "Shared Layer Changed, reset to default"
                : "Shared Layer"
            }
          >
            <ShareIcon
              style={{
                fontSize: "16px",
                marginTop: "2px",
                color: mapLayer.isSharedDataChanged ? "orange" : "initial",
              }}
            />
          </Tooltip>
        )}
    </>
  );
};

export default MapLayerShareIcon;
