const GridWindow: React.FC<{
  windowId: string;
  title: string;
  onClose: any;
  children: any;
}> = ({ windowId, title, onClose, children }) => {
  return (
    <div className="grid-window">
      <div className="grid-window-handle">
        <i className="fa fa-caret-down" />
      </div>
      <div
        className="grid-window-title d-flex align-items-center justify-content-between"
        style={{ zIndex: "100" }}
      >
        <span>{title}</span>
        <i
          className="fa fa-times img-button"
          onClick={() => onClose(windowId)}
        />
      </div>
      <div className="grid-window-content">{children}</div>
    </div>
  );
};

export default GridWindow;
