const numberTo3Digits = (
  value: number | undefined | null
): number | undefined => {
  return numberToNDigits(value, 3);
};

const numberTo4Digits = (
  value: number | undefined | null
): number | undefined => {
  return numberToNDigits(value, 4);
};

const numberTo10Digits = (
  value: number | undefined | null
): number | undefined => {
  return numberToNDigits(value, 4);
};

const numberToNDigits = (
  value: number | undefined | null,
  digits: number
): number | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }

  // const value3digitsString = Number(value).toFixed(digits);
  // const value3digitsNumber = +value3digitsString;
  // return value3digitsNumber;

  let digitDivider = 1;
  for (let i = 0; i < digits; i++) {
    digitDivider *= 10;
  }

  // return Math.floor(value * digitDivider) / digitDivider;
  return Math.round(value * digitDivider) / digitDivider;
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

const numberHelper = {
  numberTo3Digits,
  numberTo4Digits,
  numberTo10Digits,
  numberToNDigits,
  getRandomInt,
};

export default numberHelper;
