import {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import MapControl from "./controls/MapControl";
import { Fill, Stroke, Style } from "ol/style";
import { osm, vector, xyz } from "./source";
import { fromLonLat, get } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import { Feature, Map as MapObject } from "ol";
import Point from "ol/geom/Point";
import { useState } from "react";
import CircleStyle from "ol/style/Circle";
import NavigatorPosition from "../../models/navigatorPosition";
import Navigator from "../../models/navigator";
import Layers from "./layers/Layers";
import TileLayer from "./layers/TileLayer";
import VectorLayer from "./layers/VectorLayer";
import Controls from "./controls/Controls";
import FullScreenControl from "./controls/FullScreenControl";
import SessionGpsPosition from "../../models/sessionGpsPosition";
import Navigable, { NavigatorMove, SetFilters } from "../../models/navigable";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import mapService from "../../services/mapService";
import RoadLayer from "../../models/roadLayer";
import imageService from "../../services/imageService";
import mvt from "./source/mvt";
import VectorTileLayer from "./layers/VectorTileLayer";
import Filter from "../../models/filter";
import { transform } from "ol/proj";
import GpsPosition from "../../models/gpsPosition";
import appSettings from "../../appSettings";
import ProjectInfo from "../../models/projectInfo";
import LoadingIndicator from "../common/LoadingIndicator";
import * as olSource from "ol/source";
import RenderFeature from "ol/render/Feature";
import LayersIcon from "@mui/icons-material/Layers";
import MapLayers from "./MapLayers";
import TableColum from "../../models/tableColumn";
import tableService from "../../services/tableService";
import mapLayerService from "../../services/mapLayerService";
import MapLayer from "../../models/mapLayer";
import MapLayerType from "../../models/mapLayerType";
import MapLayerVisibility from "../../models/mapLayerVisibility";
import ThematicMapLayerRangeType from "../../models/thematicMapLayerRangeType";
import ThematicMapLayerLegend from "./thematic-maps/ThematicMapLayerLegend";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";
import numberHelper from "../../util/numberHelper";

const logService = new LogService(LogDirectory.EnableMapLogging);

const styles = {
  PolylineCurrent: new Style({
    stroke: new Stroke({
      color: "blue",
      width: 4,
    }),
  }),
  PolylineActive: new Style({
    stroke: new Stroke({
      color: "#16B173",
      width: 4,
    }),
  }),
  PolylineInactive: new Style({
    stroke: new Stroke({
      color: "#9A656F",
      width: 4,
    }),
  }),
};

function getCurrentPositionMarker(lonLatPosition: number[]): any {
  const iconStyle = new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: "blue",
      }),
      stroke: new Stroke({
        color: "white",
        width: 3,
      }),
    }),
  });
  const feature = new Feature({
    geometry: new Point(fromLonLat(lonLatPosition)),
  });
  feature.setStyle(iconStyle);
  return feature;
}

interface Props {
  navigator: Navigator;
  project: ProjectInfo;
  // children: React.ReactNode;
}

export interface MapRef {
  refresh: () => void;
}

const Map = forwardRef((props: Props, ref: Ref<MapRef>) => {
  const { navigator, project } = props;

  const user = useContext<User | undefined>(UserContext);

  const [roadLayer, setRoadLayer] = useState<RoadLayer>();
  const [center, setCenter] = useState<number[]>([]);
  const [zoom, setZoom] = useState(0);

  const [currentPosition, setCurrentPosition] = useState<number[]>();
  const [currentPositionMarker, setCurrentPositionMarker] = useState<any>();

  const [baseLayer, setBaseLayer] = useState<any>();

  const [allRoadsMvtSource, setAllRoadsMvtSource] = useState<
    olSource.VectorTile | undefined
  >();

  const [reportRoadsMvtSource, setReportRoadsMvtSource] = useState<
    olSource.VectorTile | undefined
  >();

  const [refreshCount, setRefreshCount] = useState(0);

  const [filtersIdRoads, setFiltersIdRoads] = useState<number[]>([]);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [noLocationFound, setNoLocationFound] = useState<boolean>(false);

  const [tableColumns, setTableColumns] = useState<TableColum[]>([]);
  const [showMapLayers, setShowMapLayers] = useState<boolean>(false);
  const [mapLayers, setMapLayers] = useState<MapLayer[]>([]);
  const [visibleThematicMapLayer, setVisibleThematicMapLayer] =
    useState<MapLayer>();

  const [containerWidth, setContainerWidth] = useState<number>();

  // const [sessionGpsPositions, setSessionGpsPositions] = useState<
  //   SessionGpsPosition[]
  // >([]);

  // const [currentIdSession, setCurrentIdSession] = useState<number>();

  useImperativeHandle(ref, () => ({ refresh }));

  const refresh = () => {
    logService.log("map:refresh()");
    setRefreshCount((prev) => prev + 1);
  };

  useEffect(() => {
    logService.log("map:int()");

    setZoom(13);
    setBaseLayer(osm());

    let currentIdSession: number | null = null;
    let currentIdRoad: number | null = null;
    let currentBatchSessionGpsPositions: SessionGpsPosition[] = [];
    let currentBatchSessionGpsPositionIndex: number = -1;

    const navigable = new Navigable();
    navigable.id = "map";

    if (project.hasAllRoadsMBTiles) {
      const allRoadsMvtSource = mvt({
        url: `${appSettings.serverUrl}/maptileservice/xyz/all-roads?idProject=${project.idProject}&x={x}&y={y}&z={z}`,
        minZoom: 0,
        maxZoom: 14,
      });
      setAllRoadsMvtSource((prevMvtSrc) => {
        if (prevMvtSrc) {
          prevMvtSrc.dispose();
        }
        return allRoadsMvtSource;
      });
    } else {
      setAllRoadsMvtSource((prevMvtSrc) => {
        if (prevMvtSrc) {
          prevMvtSrc.dispose();
        }
        return undefined;
      });
    }

    if (project.hasThematicMaps) {
      const reportRoadsMvtSource = mvt({
        url: `${appSettings.serverUrl}/maptileservice/xyz/report-segments?idProject=${project.idProject}&x={x}&y={y}&z={z}`,
        minZoom: 0,
        maxZoom: 14,
      });
      setReportRoadsMvtSource((prevMvtSrc) => {
        if (prevMvtSrc) {
          prevMvtSrc.dispose();
        }
        return reportRoadsMvtSource;
      });
    } else {
      setReportRoadsMvtSource((prevMvtSrc) => {
        if (prevMvtSrc) {
          prevMvtSrc.dispose();
        }
        return undefined;
      });
    }

    const init = async () => {
      if (!project.hasThematicMaps) {
        return;
      }

      const { data: tableColumns } = await tableService.getTableColumns(
        project.idProject
      );

      setTableColumns(tableColumns);

      const mapLayers = await mapLayerService.getMapLayers(
        project.idProject,
        tableColumns
      );

      setMapLayers(mapLayers);

      const visibleThematicMapLayer = mapLayers.find(
        (ml) => ml.mapLayerType === MapLayerType.ThematicLayer && ml.isVisible
      );
      setVisibleThematicMapLayer(visibleThematicMapLayer);
    };

    const loadData = async (navigatorPosition: NavigatorPosition) => {
      logService.log("map:loadData()");
      try {
        const { data: roadLayer } = await mapService.getRoadLayer(
          project.idProject,
          navigatorPosition.idRoad
        );
        setRoadLayer(roadLayer);

        const { data: sessionGpsPositionIndex } =
          await mapService.getSessionGpsPositionIndex(
            project.idProject,
            navigatorPosition.idSession,
            navigatorPosition.distance
          );

        let startIndex =
          sessionGpsPositionIndex - imageService.preloaderBackCacheSize * 35;
        if (startIndex < 0) {
          startIndex = 0;
        }
        const stopIndex = startIndex + mapService.batchSize;

        const { data: sessionGpsPositions } =
          await mapService.getSessionGpsPositions(
            project.idProject,
            navigatorPosition.idSession,
            startIndex,
            stopIndex
          );

        currentBatchSessionGpsPositions = sessionGpsPositions;

        logService.log("map:loadData()-done");
      } catch (error) {
        logService.log(error);
      }
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log("map:navigator:move()");

      if (
        currentIdSession === navigatorPosition.idSession &&
        currentIdRoad === navigatorPosition.idRoad
      ) {
        currentBatchSessionGpsPositionIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchSessionGpsPositions,
            false
          );
      } else {
        currentBatchSessionGpsPositionIndex = -1;
        currentIdSession = navigatorPosition.idSession;
        currentIdRoad = navigatorPosition.idRoad;
      }

      let isLoadData = false;
      if (currentBatchSessionGpsPositionIndex === -1) {
        isLoadData = true;
        await loadData(navigatorPosition);
      }

      currentBatchSessionGpsPositionIndex =
        NavigatorPosition.getClosestNavigatorPositionIndexExact(
          navigatorPosition,
          currentBatchSessionGpsPositions,
          false
        );

      if (currentBatchSessionGpsPositionIndex === -1) {
        currentBatchSessionGpsPositionIndex = 0;
      }

      if (currentBatchSessionGpsPositions.length === 0) {
        return;
      }

      const sessionGpsPosition =
        currentBatchSessionGpsPositions[currentBatchSessionGpsPositionIndex];

      if (sessionGpsPosition.gpsPosition !== null) {
        const lonLatPosition = [
          sessionGpsPosition.gpsPosition.longitude,
          sessionGpsPosition.gpsPosition.latitude,
        ];

        if (isLoadData) {
          setCenter(lonLatPosition);
        }

        setCurrentPosition(lonLatPosition);
        setCurrentPositionMarker(getCurrentPositionMarker(lonLatPosition));
      }

      navigator.gpsPosition = sessionGpsPosition.gpsPosition;
      navigator.setGpsPosition(sessionGpsPosition.gpsPosition);
    };
    navigable.navigatorMove = navigatorMove;

    const navigatorSetFilters: SetFilters = (filters: Filter[]) => {
      setFiltersIdRoads(navigator.filtersIdRoads);
      return Promise.resolve();
    };
    navigable.setFilters = navigatorSetFilters;

    navigator.attach(navigable);

    // this sould be called on open/close window
    if (
      navigator.navigatorPosition &&
      navigator.navigatorPosition.idProject === project.idProject
    ) {
      logService.log("map:navigator:move()-init");
      navigatorMove(navigator.navigatorPosition);
    }

    // setTimeout(() => {
    //   const lonlat = [-111.43163717989924, 35.85683404274522];
    //   setCurrentPosition(lonlat);
    //   setCurrentPositionMarker(getCurrentPositionMarker(lonlat));
    //   setZoom(9);
    // }, 10000);

    init();

    return () => {
      navigator.gpsPosition = null;
      navigator.setGpsPosition(null);
      navigator.detach(navigable);
    };
  }, [navigator, project]);

  const onReloadMapLayers = async () => {
    const mapLayers = await mapLayerService.getMapLayers(
      project.idProject,
      tableColumns
    );

    setMapLayers(mapLayers);

    setVisibleThematicMapLayer(undefined);

    for (const mapLayer of mapLayers) {
      if (mapLayer.mapLayerType === MapLayerType.ThematicLayer) {
        if (mapLayer.isVisible) {
          setVisibleThematicMapLayer(mapLayer);
        }
      }
    }
  };

  const onCreateMapLayer = async (mapLayer: MapLayer) => {
    if (!user?.isDemoUser) {
      await updateOtherMapLayersToBeInvisible(); // note that new created layer is not yet loaded in mapLayers
      await mapLayerService.createMapLayer(mapLayer);
      await onReloadMapLayers();
    } else {
      for (const otherThematicMapLayer of mapLayers.filter(
        (ml) => ml.mapLayerType === MapLayerType.ThematicLayer
      )) {
        otherThematicMapLayer.isVisible = false;
      }

      mapLayer.idMapLayer = numberHelper.getRandomInt(1000);

      const newMapLayers = [...mapLayers];
      newMapLayers.push(mapLayer);

      setMapLayers(newMapLayers);

      setVisibleThematicMapLayer(mapLayer);
    }
  };

  const onUpdateMapLayer = async (mapLayer: MapLayer) => {
    if (!user?.isDemoUser) {
      await mapLayerService.updateMapLayer(mapLayer);
      await onReloadMapLayers();
    } else {
      const newMapLayers = mapLayers.filter(
        (ml) => ml.idMapLayer !== mapLayer.idMapLayer
      );

      newMapLayers.push(mapLayer);

      setMapLayers(newMapLayers);

      setVisibleThematicMapLayer(mapLayer);
    }
  };

  const onDeleteMapLayer = async (mapLayer: MapLayer) => {
    if (!user?.isDemoUser) {
      await mapLayerService.deleteMapLayer(mapLayer.idMapLayer);
      await onReloadMapLayers();
    } else {
      const newMapLayers = mapLayers.filter(
        (ml) => ml.idMapLayer !== mapLayer.idMapLayer
      );

      setMapLayers(newMapLayers);

      setVisibleThematicMapLayer(undefined);
    }
  };

  const updateOtherMapLayersToBeInvisible = async () => {
    const mapLayerVisibilities: MapLayerVisibility[] = [];

    for (const otherThematicMapLayer of mapLayers.filter(
      (ml) => ml.mapLayerType === MapLayerType.ThematicLayer
    )) {
      const mapLayerVisibility = new MapLayerVisibility();
      mapLayerVisibility.idMapLayer = otherThematicMapLayer.idMapLayer;
      mapLayerVisibility.isVisible = false;
      mapLayerVisibilities.push(mapLayerVisibility);
    }

    await mapLayerService.updateMapLayerVisibilities(mapLayerVisibilities);
  };

  const onUpdateMapLayerVisibility = (
    mapLayer: MapLayer,
    isVisible: boolean
  ) => {
    if (mapLayer.mapLayerType === MapLayerType.ThematicLayer) {
      if (isVisible) {
        setVisibleThematicMapLayer(mapLayer);
      } else {
        setVisibleThematicMapLayer(undefined);
      }
    }

    const mapLayerVisibilities: MapLayerVisibility[] = [];

    const newMapLayers = structuredClone(mapLayers);

    for (const newMapLayer of newMapLayers) {
      if (newMapLayer.idMapLayer === mapLayer.idMapLayer) {
        newMapLayer.isVisible = isVisible;

        const mapLayerVisibility = new MapLayerVisibility();
        mapLayerVisibility.idMapLayer = newMapLayer.idMapLayer;
        mapLayerVisibility.isVisible = newMapLayer.isVisible;
        mapLayerVisibilities.push(mapLayerVisibility);
      }
    }

    if (mapLayer.mapLayerType === MapLayerType.ThematicLayer) {
      if (isVisible === true) {
        const otherThematicMapLayers = newMapLayers.filter(
          (ml) =>
            ml.mapLayerType === MapLayerType.ThematicLayer &&
            ml.idMapLayer !== mapLayer.idMapLayer
        );

        for (const otherThematicMapLayer of otherThematicMapLayers) {
          otherThematicMapLayer.isVisible = false;

          const mapLayerVisibility = new MapLayerVisibility();
          mapLayerVisibility.idMapLayer = otherThematicMapLayer.idMapLayer;
          mapLayerVisibility.isVisible = otherThematicMapLayer.isVisible;
          mapLayerVisibilities.push(mapLayerVisibility);
        }
      }
    }

    setMapLayers(newMapLayers);

    if (!user?.isDemoUser) {
      mapLayerService.updateMapLayerVisibilities(mapLayerVisibilities);
      // await onReloadMapLayers();
    }
  };

  const projection = get("EPSG:3857");

  const onMapClick = async (evt: any) => {
    const lonlat = transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
    const [lon, lat] = lonlat;

    const gpsPosition = new GpsPosition();
    gpsPosition.longitude = lon;
    gpsPosition.latitude = lat;

    const map = evt.map as MapObject;
    const zoom = map.getView().getZoom();
    const zoomLevel = Math.round(zoom ?? 0);

    console.log("MapClick lonlat, zoomLevel", lonlat, zoomLevel);

    setIsBusy(true);
    setNoLocationFound(false);

    const { data: navigatorPosition } =
      await mapService.getClosestNavigatorPositionForGpsPosition(
        project.idProject,
        gpsPosition,
        zoomLevel,
        []
      );
    if (navigatorPosition) {
      if (navigator.filters.length > 0) {
        const isRoadInFilters = filtersIdRoads.find(
          (filterIdRoad) => filterIdRoad === navigatorPosition.idRoad
        );
        if (!isRoadInFilters) {
          // clear filters
          await navigator.setFilters(project.idProject, []);
        }
      }

      await navigator.move(navigatorPosition);
      setIsBusy(false);
    } else {
      setIsBusy(false);
      setNoLocationFound(true);
      setTimeout(() => setNoLocationFound(false), 2000);
    }
  };

  const onChangeBasemap = (event: any) => {
    const layerType = event.target.value;

    if (layerType === "osm") {
      setBaseLayer(osm());
    }

    if (layerType === "aerial") {
      const xyzLayer = xyz({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        maxZoom: 19,
      });
      setBaseLayer(xyzLayer);
    }
  };

  const allRoadsFeatureStyle = useCallback(
    (feature: RenderFeature, source: olSource.VectorTile) => {
      const networkMapLayer = mapLayers.find(
        (ml) => ml.mapLayerType === MapLayerType.NetworkLayer
      );

      if (networkMapLayer) {
        if (!networkMapLayer.isVisible) {
          return;
        }
      }

      if (filtersIdRoads.length > 0) {
        const featureRoadId = feature.getId(); // or feature.getProperties()["idRoad"]
        const hasRoad = filtersIdRoads.find(
          (filterIdRoad) => filterIdRoad === featureRoadId
        );
        if (hasRoad) {
          return styles.PolylineActive;
        } else {
          return styles.PolylineInactive;
        }
      }

      return styles.PolylineActive;

      // return new Style({
      //   stroke: new Stroke({
      //     color: "#FF13F0", // neon pink
      //     width: 4,
      //   }),
      // });
    },
    [filtersIdRoads, mapLayers]
  );

  const reportRoadsFeatureStyle = useCallback(
    (feature: RenderFeature, source: olSource.VectorTile) => {
      // if (filtersIdRoads.length > 0) {
      //   const featureRoadId = feature.getId(); // or feature.getProperties()["idRoad"]
      //   const hasRoad = filtersIdRoads.find(
      //     (filterIdRoad) => filterIdRoad === featureRoadId
      //   );
      //   if (hasRoad) {
      //     return styles.PolylineActive;
      //   } else {
      //     return styles.PolylineInactive;
      //   }
      // }

      // return styles.PolylineActive;

      if (filtersIdRoads.length > 0) {
        const featureRoadId = feature.getId(); // or feature.getProperties()["idRoad"]
        const hasRoad = filtersIdRoads.find(
          (filterIdRoad) => filterIdRoad === featureRoadId
        );
        if (!hasRoad) {
          //return styles.PolylineInactive;
          return new Style({
            stroke: new Stroke({
              color: "transparent",
              width: 4,
            }),
          });
        }
      }

      //console.log(feature);
      //console.log(feature, source);
      //console.log(source);

      // const hasIDRoad = feature.getProperties()["IRI Avg"] !== undefined;
      // console.log("hasIDRoad", hasIDRoad, avgIri);

      // const idRoad = feature.getProperties()["idRoad"];

      // Good: IRI up to 95 inches/mi (1.50 m/km). Fair: IRI up to 170 inches/mi (2.68 m/km). Poor: IRI up to 220 inches/mile (3.47 m/km). Very poor: IRI exceeding 220 inches/mi (3.47 m/km)

      const thematicLayer = mapLayers.find(
        (l) => l.mapLayerType === MapLayerType.ThematicLayer && l.isVisible
      );

      if (!thematicLayer || !thematicLayer.thematicMapLayer) {
        return;
      }

      const fieldValue =
        feature.getProperties()[thematicLayer.thematicMapLayer.fieldName];

      // const fieldValue = feature.getProperties()["IRI Avg"];

      if (
        thematicLayer.thematicMapLayer.rangeType ===
        ThematicMapLayerRangeType.Continuous
      ) {
        for (const range of thematicLayer.thematicMapLayer
          .thematicMapLayerRanges) {
          if (!range.hasRangeStart) {
            if (fieldValue <= range.rangeEndValue) {
              return new Style({
                stroke: new Stroke({
                  color: range.rangeColor,
                  width: 4,
                }),
              });
            }
          } else if (!range.hasRangeEnd) {
            if (fieldValue > range.rangeStartValue) {
              return new Style({
                stroke: new Stroke({
                  color: range.rangeColor,
                  width: 4,
                }),
              });
            }
          } else {
            if (
              fieldValue > range.rangeStartValue &&
              fieldValue <= range.rangeEndValue
            ) {
              return new Style({
                stroke: new Stroke({
                  color: range.rangeColor,
                  width: 4,
                }),
              });
            }
          }
        }
      } else if (
        thematicLayer.thematicMapLayer.rangeType ===
        ThematicMapLayerRangeType.Discrete
      ) {
        for (const range of thematicLayer.thematicMapLayer
          .thematicMapLayerRanges) {
          if (fieldValue === range.rangeValue) {
            return new Style({
              stroke: new Stroke({
                color: range.rangeColor,
                width: 4,
              }),
            });
          }
        }
      }

      // const test

      // const fieldValue = feature.getProperties()["IRI Avg"];

      // if (fieldValue <= 1.5) {
      //   // console.log(`idRoad: ${idRoad}, fieldValue: ${fieldValue}, good`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "green",
      //       width: 4,
      //     }),
      //   });
      // } else if (fieldValue <= 2.68) {
      //   // console.log(`idRoad: ${idRoad}, fieldValue: ${fieldValue}, fair`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "orange",
      //       width: 4,
      //     }),
      //   });
      // } else if (fieldValue <= 3.47) {
      //   // console.log(`idRoad: ${idRoad}, fieldValue: ${fieldValue}, poor`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "red",
      //       width: 4,
      //     }),
      //   });
      // } else {
      //   // console.log(`idRoad: ${idRoad}, fieldValue: ${fieldValue}, very poor`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "darkred",
      //       width: 4,
      //     }),
      //   });
      // }

      // const test old

      // const avgIri = feature.getProperties()["IRI Avg"];

      // if (avgIri <= 1.5) {
      //   // console.log(`idRoad: ${idRoad}, avgIri: ${avgIri}, good`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "green",
      //       width: 4,
      //     }),
      //   });
      // } else if (avgIri <= 2.68) {
      //   // console.log(`idRoad: ${idRoad}, avgIri: ${avgIri}, fair`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "orange",
      //       width: 4,
      //     }),
      //   });
      // } else if (avgIri <= 3.47) {
      //   // console.log(`idRoad: ${idRoad}, avgIri: ${avgIri}, poor`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "red",
      //       width: 4,
      //     }),
      //   });
      // } else {
      //   // console.log(`idRoad: ${idRoad}, avgIri: ${avgIri}, very poor`);
      //   return new Style({
      //     stroke: new Stroke({
      //       color: "darkred",
      //       width: 4,
      //     }),
      //   });
      // }

      ///////////////////////////////////////

      // return new Style({
      //   stroke: new Stroke({
      //     color: "red",
      //     width: 4,
      //   }),
      // });
    },
    [filtersIdRoads, mapLayers]
  );

  const onLayersMenuClick = async () => {
    setShowMapLayers(!showMapLayers);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  setTimeout(() => {
    setContainerWidth(containerRef.current?.clientWidth);
  }, 500);

  return (
    <div ref={containerRef}>
      <MapControl
        center={center}
        zoom={zoom}
        positionInView={currentPosition}
        refreshCount={refreshCount}
        onClick={onMapClick}
      >
        <Layers>
          <TileLayer source={baseLayer} zIndex={0} />
          {allRoadsMvtSource && (
            <VectorTileLayer
              style={(feature: any) =>
                allRoadsFeatureStyle(feature, allRoadsMvtSource)
              }
              //style={styles.PolylineGreen}
              source={allRoadsMvtSource}
              zIndex={1}
            />
          )}
          {reportRoadsMvtSource && (
            <VectorTileLayer
              style={(feature: any) =>
                reportRoadsFeatureStyle(feature, reportRoadsMvtSource)
              }
              //style={styles.PolylineGreen}
              source={reportRoadsMvtSource}
              zIndex={2}
            />
          )}
          {roadLayer && projection && (
            <VectorLayer
              style={styles.PolylineCurrent}
              source={vector({
                features: new GeoJSON().readFeatures(roadLayer.geometry, {
                  featureProjection: projection,
                }),
              })}
              zIndex={3}
            />
          )}
          {currentPositionMarker && (
            <VectorLayer
              source={vector({ features: [currentPositionMarker] })}
              zIndex={4}
            />
          )}
        </Layers>
        <Controls>
          <FullScreenControl />
        </Controls>
      </MapControl>
      {project.hasThematicMaps && (
        <>
          <div
            style={{
              position: "absolute",
              top: "4px",
              left: "4px",
              zIndex: "2",
            }}
          >
            <div className="d-flex">
              <div
                className={
                  "image-button" // + (showMapLayers ? " image-button-active" : "")
                }
                style={{ marginBottom: "4px" }}
                onClick={onLayersMenuClick}
              >
                <LayersIcon style={{ height: "20px" }} />
              </div>
            </div>
            <div>
              {showMapLayers && (
                <MapLayers
                  project={project}
                  mapLayers={mapLayers}
                  tableColumns={tableColumns}
                  onCreateMapLayer={onCreateMapLayer}
                  onUpdateMapLayer={onUpdateMapLayer}
                  onDeleteMapLayer={onDeleteMapLayer}
                  onUpdateMapLayerVisibility={onUpdateMapLayerVisibility}
                />
              )}
            </div>
          </div>
          {visibleThematicMapLayer && (
            <div
              style={{
                position: "absolute",
                bottom: "4px",
                left: "4px",
                width: containerWidth ? containerWidth - 5 : containerWidth,
                overflow: "hidden",
              }}
            >
              <ThematicMapLayerLegend mapLayer={visibleThematicMapLayer} />
            </div>
          )}
        </>
      )}
      <div style={{ position: "absolute", top: "6px", right: "40px" }}>
        <select style={{ padding: "2px 4px" }} onChange={onChangeBasemap}>
          <option value="osm">Streets</option>
          <option value="aerial">Aerial</option>
        </select>
      </div>
      {isBusy && <LoadingIndicator className="map" />}
      {noLocationFound && (
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            zIndex: "2",
            margin: "3px",
          }}
        >
          <div
            style={{ padding: "0px 2px", margin: "0px", lineHeight: "1.5" }}
            className="alert alert-danger"
          >
            No data found at selected location!
          </div>
        </div>
      )}
    </div>
  );
});

export default Map;
