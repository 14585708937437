import MapLayerType from "./mapLayerType";
import NetworkMapLayer from "./networkMapLayer";
import ThematicMapLayer from "./thematicMapLayer";

export default class MapLayer {
  public idMapLayer: number = 0;
  public idProject: number = 0;
  public idUser: number | undefined = undefined;
  public idParentMapLayer: number | undefined = undefined;

  public layerName: string = "";
  public mapLayerType: MapLayerType = MapLayerType.ThematicLayer;
  public thickness: number = 0;
  public isVisible: boolean = true;
  public isShared: boolean = false;
  public isSharedDataChanged: boolean = false;
  public isDefault: boolean = false;

  public index: number = 0;

  public parentMapLayer: MapLayer | undefined = undefined;
  public networkMapLayer: NetworkMapLayer | undefined = undefined;
  public thematicMapLayer: ThematicMapLayer | undefined = undefined;
}
