import React, {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ImagePreloader from "../../models/imagePreloader";
import NavigatorPosition from "../../models/navigatorPosition";
import ImageNotAvailableImage from "../../assets/images/image-not-available-wide.png";
import Navigable, { NavigatorMove } from "../../models/navigable";
import Navigator from "../../models/navigator";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import imageService from "../../services/imageService";
import ImageCacheItem from "../../models/imageCacheItem";
import ProjectInfo from "../../models/projectInfo";
import { DistressRecord } from "../../models/distressRecord";
import pavementImageService from "../../services/pavementImageService";
import DistressCategory from "../../models/distressCategory";
import colorHelper from "../../util/colorHelper";
import Settings from "../../models/settings";
import PavementImageFrame from "../../models/pavementImageFrame";
import geometryHelper from "../../util/geometryHelper";
import unitConverter from "../../util/units/unitConverter";
import numberHelper from "../../util/numberHelper";
import SessionEvents from "../../models/sessionEvents";
import eventService from "../../services/eventService";
import Range from "../../util/range";
import DrawItems from "./DrawItems/DrawItems";
import roadManager from "../../models/roadManager";
import ImageHd from "../../assets/images/image-hd.png";
import FullScreen from "../../assets/images/full_screen.png";
import FullScreenExit from "../../assets/images/full_screen_exit.png";
import ZoomFit from "../../assets/images/zoom_fit.png";
import CameraPavementOptions from "./CameraPavementOptions";

const logService = new LogService(LogDirectory.EnableCameraLogging);

export interface CameraPavementRef {
  refresh: () => void;
}

interface Props {
  cameraName: string;
  navigator: Navigator;
  project: ProjectInfo;
  settings: Settings;
  //children: React.ReactNode;
}

interface CurrentTransform {
  scaleX: number;
  scaleY: number;
}

const CameraPavement = forwardRef(
  (props: Props, ref: Ref<CameraPavementRef>) => {
    const { cameraName, navigator, project, settings } = props;

    useImperativeHandle(ref, () => ({ refresh }));

    //const [refreshCount, setRefreshCount] = useState(0);

    const [showCameraOptions, setShowCameraOptions] = useState<boolean>(false);

    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

    const [imageUrl, setImageUrl] = useState<string>("");
    const [imageOriginalUrl, setImageOriginalUrl] = useState<string>("");
    const [showCameraOrginalUrl, setShowCameraOrginalUrl] =
      useState<boolean>(false);

    const [image, setImage] = useState<HTMLImageElement>();

    const [isLoadFullsizeImage, setIsLoadFullsizeImage] =
      useState<boolean>(false);

    const camContainerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasTooltipRef = useRef<HTMLDivElement>(null);

    const [imageFrame, setImageFrame] = useState<
      PavementImageFrame | undefined
    >(undefined);

    const [distanceRange, setDistanceRange] = useState<Range<number>>(
      new Range(0, 0)
    );

    const [distressRecords, setDistressRecords] = useState<DistressRecord[]>(
      []
    );

    const [drawItems, setDrawItems] = useState<DrawItems>(new DrawItems());

    const [showDistress, setShowDistress] = useState<boolean>(false);
    const [showDistressBoxes, setShowDistressBoxes] = useState<boolean>(false);
    const [showDistressLabels, setShowDistressLabels] =
      useState<boolean>(false);

    const [showEvents, setShowEvents] = useState<boolean>(false);
    const [sessionEvents, setSessionEvents] = useState<SessionEvents>();

    useEffect(() => {
      setCanvasSize();

      document.onfullscreenchange = (event) => {
        if (!document.fullscreenElement) {
          setIsFullScreen(false);
        }
      };
    }, []);

    const refresh = () => {
      // setRefreshCount((prev) => prev + 1);
      // console.log("pavementCamRefresh()");
      setCanvasSize();
      initImageScale(image);

      const drawItems = redraw(
        image,
        imageFrame,
        distanceRange,
        distressRecords,
        sessionEvents
      );
      setDrawItems(drawItems);
    };

    const initImageScale = (image: HTMLImageElement | undefined) => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (!context || !canvas || !image) {
        return;
      }

      var hRatio = canvas.width / image.width;
      var vRatio = canvas.height / image.height;
      var ratio = Math.min(hRatio, vRatio);
      // console.log("hRatio", hRatio);
      // console.log("vRatio", vRatio);
      // console.log("ratio", ratio);
      // context.drawImage(
      //   image,
      //   0,
      //   0,
      //   image.width,
      //   image.height,
      //   0,
      //   0,
      //   image.width * ratio,
      //   image.height * ratio
      // );
      const hasVerticalSpace = ratio === hRatio;
      const hasHorizontalSpace = ratio === vRatio;
      if (hasVerticalSpace) {
        const vTranslation = (canvas.height - image.height * ratio) / 2;
        context.translate(0, vTranslation);
      } else if (hasHorizontalSpace) {
        const hTranslation = (canvas.width - image.width * ratio) / 2;
        context.translate(hTranslation, 0);
      }
      context.scale(ratio, ratio);
    };

    const setCanvasSize = () => {
      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    const getScalePixelPerMeter = (
      image: HTMLImageElement,
      imageFrame: PavementImageFrame
    ) => {
      // = imageWidth / frameWidth
      //scalePixelPerMeter = this.dataContext.ViewportWidth / this.dataContext.Stream.PavementStreamRow.FrameWidth;

      // dimensions = width * height
      // const imageWidth = 205;
      // const imageHeight = 304;

      const imageWidth = image.width;
      //const imageHeight = image.height;

      // pavement frame
      const frameWidth = imageFrame.frameWidth;
      //const frameWidth = 4.108; // meters
      //const frameLength = 6.096; // meters

      const scalePixelPerMeter = imageWidth / frameWidth;
      //const scalePixelPerMeter = getViewPortWidth(image) / frameWidth;
      return scalePixelPerMeter;
    };

    const getViewportWidth = (image: HTMLImageElement): number => {
      //this.dataContext.ViewportWidth
      return image.width;
    };

    const getViewportHeight = (image: HTMLImageElement): number => {
      //this.dataContext.ViewportHeight
      return image.height;
    };

    const getViewportDistance = (imageFrame: PavementImageFrame) => {
      //this.dataContext.Distance
      return imageFrame.distance;
    };

    const drawDistressLine = useCallback(
      (
        drawItems: DrawItems,
        context: CanvasRenderingContext2D,
        d: DistressRecord,
        y1: number,
        y2: number,
        x1: number,
        x2: number,
        brush: string,
        solid: boolean = true
      ) => {
        // context.fillRect(x1, y1, 2, 2);
        // context.fillRect(x2, y2, 2, 2);

        const dotArray = [5, 12];

        context.beginPath();

        context.moveTo(x1, y1);
        context.lineTo(x2, y2);

        context.lineWidth = 3 * d.distressSeverity.ordinalPosition;
        context.strokeStyle = colorHelper.hexArgbToRgbAHex(brush);

        context.lineCap = "square";
        context.setLineDash([]);

        if (!solid) {
          context.setLineDash(dotArray);
        } else if (d.isAutomatic) {
          context.lineCap = "round";
        }

        context.save();
        context.setTransform(1, 0, 0, 1, 0, 0); // identity matrix
        context.stroke();
        context.restore();

        drawItems.distressLines.push({ d, y1, y2, x1, x2 });
      },
      []
    );

    const getCurrentTransform = (
      context: CanvasRenderingContext2D
    ): CurrentTransform => {
      const currentTransform = context.getTransform();

      // transform properties deconstructed
      const hScale = currentTransform.m11;
      // const vSkew = currentTransform.m12;
      // const hSkew = currentTransform.m21;
      const vScale = currentTransform.m22;
      //const hTranslation = currentTransform.dx;
      //const vTranslation = currentTransform.dy;

      //context.setTransform(hScale, vSkew, hSkew, vScale, 0, 0);

      return { scaleX: vScale, scaleY: hScale };
    };

    const drawDistress = useCallback(
      (
        context: CanvasRenderingContext2D,
        image: HTMLImageElement,
        imageFrame: PavementImageFrame,
        scalePixelPerMeter: number,
        distressRecords: DistressRecord[],
        clearOusideArea: boolean,
        drawItems: DrawItems
      ) => {
        const currentTransform = getCurrentTransform(context);
        const scaleX = currentTransform.scaleX;
        const scaleY = currentTransform.scaleY;

        const diameter = 11 / scaleY;

        for (const d of distressRecords) {
          let y1 =
            getViewportHeight(image) -
            (d.y1 - getViewportDistance(imageFrame)) * scalePixelPerMeter;
          let y2 =
            getViewportHeight(image) -
            (d.y2 - getViewportDistance(imageFrame)) * scalePixelPerMeter;
          let x1 = d.x1 * scalePixelPerMeter;
          let x2 = d.x2 * scalePixelPerMeter;

          const brush = d.distressSeverity.distressType.fillColor;

          //console.log(brush);

          let distressCategory =
            d.distressSeverity.distressType.distressCategory;
          const automaticCategory = distressCategory;
          const wasArea = distressCategory === DistressCategory.Area;

          if (d.isAutomatic && showDistressBoxes) {
            distressCategory = DistressCategory.Area; //show all automatic distresses as rectangles
          }

          switch (distressCategory) {
            case DistressCategory.Longitudinal:
            case DistressCategory.Transversal:
              if (d.isAutomatic) {
                if (automaticCategory === DistressCategory.Transversal) {
                  y1 = y2 = (y1 + y2) / 2;
                } else {
                  x1 = x2 = (x1 + x2) / 2;
                }
              }
              drawDistressLine(drawItems, context, d, y1, y2, x1, x2, brush);
              break;

            case DistressCategory.Point:
              drawDistressLine(
                drawItems,
                context,
                d,
                y2 - diameter,
                y2 + diameter,
                x2,
                x2,
                brush
              );
              drawDistressLine(
                drawItems,
                context,
                d,
                y2,
                y2,
                x2 - diameter,
                x2 + diameter,
                brush
              );
              break;
            case DistressCategory.Area:
              drawDistressLine(
                drawItems,
                context,
                d,
                y1,
                y2,
                x1,
                x1,
                brush,
                wasArea || !d.isAutomatic
              );
              drawDistressLine(
                drawItems,
                context,
                d,
                y2,
                y2,
                x1,
                x2,
                brush,
                wasArea || !d.isAutomatic
              );
              drawDistressLine(
                drawItems,
                context,
                d,
                y2,
                y1,
                x2,
                x2,
                brush,
                wasArea || !d.isAutomatic
              );
              drawDistressLine(
                drawItems,
                context,
                d,
                y1,
                y1,
                x2,
                x1,
                brush,
                wasArea || !d.isAutomatic
              );
              if (d.isAutomatic && !wasArea) {
                if (automaticCategory === DistressCategory.Transversal) {
                  y1 = y2 = (y1 + y2) / 2;
                } else {
                  x1 = x2 = (x1 + x2) / 2;
                }
                drawDistressLine(drawItems, context, d, y1, y2, x1, x2, brush);
              }
              break;
          }

          if (showDistressLabels) {
            let y = Math.min(y1, y2);
            let x = y === y1 ? x1 : x2;

            const text = d.distressSeverity.distressType.distressTypeName;

            context.textAlign = "left";

            if (x > image.width / 2) {
              context.textAlign = "right";
              x -= 8 / scaleX;
              y += 14 / scaleY;
            } else {
              x += 10 / scaleX;
              y += 20 / scaleY;
            }

            const canShow =
              !clearOusideArea ||
              (clearOusideArea &&
                y > 0 &&
                y < imageFrame.frameLength * scalePixelPerMeter);

            if (canShow) {
              context.save();

              context.font = "bold 11px sans-serif";
              context.translate(x, y);
              context.scale(1 / scaleX, 1 / scaleY);
              context.fillStyle = colorHelper.hexArgbToRgbAHex(brush);
              context.fillText(text, 0, 0);

              context.restore();
            }
          }
        }
      },
      [drawDistressLine, showDistressBoxes, showDistressLabels]
    );

    const drawEvents = useCallback(
      (
        context: CanvasRenderingContext2D,
        image: HTMLImageElement,
        imageFrame: PavementImageFrame,
        scalePixelPerMeter: number,
        distanceRange: Range<number>,
        sessionEvents: SessionEvents | undefined,
        drawItems: DrawItems
      ) => {
        if (!sessionEvents) {
          return;
        }

        const eventRecords = sessionEvents.eventRecords;

        const eventRecordsInRange = eventRecords.filter((er) =>
          distanceRange.contains(er.distance)
        );

        // console.log("distanceRange", distanceRange);
        // console.log("sessionEvents", sessionEvents);
        // console.log("eventRecordsInRange", eventRecordsInRange);

        const group = Object.groupBy(
          eventRecordsInRange,
          ({ distance }) => distance
        );
        const groupKeys = Object.keys(group);
        for (const key of groupKeys) {
          const sameDistanceEvents = group[Number(key)];
          if (!sameDistanceEvents) {
            continue;
          }

          // var dupEvent = { ...sameDistanceEvents[0], fillColor: "red" };
          // sameDistanceEvents.push(dupEvent);
          // var dupEvent = { ...sameDistanceEvents[0], fillColor: "red" };
          // sameDistanceEvents.push(dupEvent);

          let eventSymbolWidth = 16;
          let eventSymbolHeight = 16;

          // x is always in pixels
          const xInitial = eventSymbolWidth + 5; // px
          const xSpacing = 15;
          let index = 0;

          for (const eventRecord of sameDistanceEvents) {
            const currentTransform = getCurrentTransform(context);
            const scaleX = currentTransform.scaleX;
            const scaleY = currentTransform.scaleY;

            const y =
              getViewportHeight(image) -
              (eventRecord.distance - getViewportDistance(imageFrame)) *
                scalePixelPerMeter;

            const x = xInitial + index * eventSymbolWidth + index * xSpacing;

            context.beginPath();

            const xTranslate = x / scaleX; // cancel scale effect on x
            const yTranslate = y;

            // context.fillStyle = "white";
            // context.fillRect(xTranslate, yTranslate, 10 / scaleX, 10 / scaleY);

            context.save();

            context.translate(xTranslate, yTranslate);

            context.rotate((45 * Math.PI) / 180);

            context.scale(1 / scaleX, 1 / scaleY);

            context.fillStyle = colorHelper.hexArgbToRgbAHex(
              eventRecord.fillColor
            );

            context.rect(
              -eventSymbolWidth / 2,
              -eventSymbolHeight / 2,
              eventSymbolWidth,
              eventSymbolHeight
            );

            context.fill();

            context.restore();

            drawItems.eventPoints.push({
              e: eventRecord,
              y: y,
              x: x,
            });

            index++;
          }
        }
      },
      []
    );

    const drawRangeEvents = useCallback(
      (
        context: CanvasRenderingContext2D,
        image: HTMLImageElement,
        imageFrame: PavementImageFrame,
        scalePixelPerMeter: number,
        distanceRange: Range<number>,
        sessionEvents: SessionEvents | undefined,
        drawItems: DrawItems
      ) => {
        if (!sessionEvents) {
          return;
        }

        const eventRangeRecords = sessionEvents.eventRangeRecords;

        const eventRangeRecordsInRange = eventRangeRecords.filter(
          (er) =>
            distanceRange.overlaps(
              new Range(er.startDistance, er.endDistance)
            ) && er.showOverlay
        );

        // const dupEvent = { ...eventRangeRecordsInRange[0], fillColor: "red" };
        // eventRangeRecordsInRange.push(dupEvent);

        // console.log("distanceRange", distanceRange);
        // console.log("sessionEvents", sessionEvents);
        // console.log("eventRangeRecords", eventRangeRecords);
        // console.log("eventRangeRecordsInRange", eventRangeRecordsInRange);
        // console.log("scalePixelPerMeter", scalePixelPerMeter);
        // console.log("getViewportDistance", getViewportDistance(imageFrame));

        const rangesToDisplayAtTheSameTime: number =
          eventRangeRecordsInRange.length;
        const thickness =
          getViewportWidth(image) / rangesToDisplayAtTheSameTime;
        let startingX: number = 0;

        for (const rangeEvent of eventRangeRecordsInRange) {
          const rangeEventRange = new Range<number>(
            rangeEvent.startDistance,
            rangeEvent.endDistance
          );

          const top =
            getViewportHeight(image) -
            (rangeEventRange.getUpperBound() -
              getViewportDistance(imageFrame)) *
              scalePixelPerMeter;
          const bottom =
            getViewportHeight(image) -
            (rangeEventRange.getLowerBound() -
              getViewportDistance(imageFrame)) *
              scalePixelPerMeter;
          const topR = Math.max(0, Math.min(top, getViewportHeight(image)));
          const bottomR = Math.max(
            0,
            Math.min(bottom, getViewportHeight(image))
          );

          // console.log("top", top);
          // console.log("bottom", bottom);
          // console.log("topR", topR);
          // console.log("bottomR", bottomR);

          context.beginPath();

          context.fillStyle = colorHelper.hexArgbToRgbAHex(
            rangeEvent.fillColor
          );

          context.rect(startingX, topR, thickness, bottomR);

          context.save();

          // Opacity = 0.5 * this.dataContext.RenderSettings.Opacity / 100.0
          const opacity = (0.5 * 60) / 100.0;
          context.globalAlpha = opacity;

          //context.setTransform(1, 0, 0, 1, 0, 0); // identity matrix
          //context.stroke();

          context.fill();

          context.restore();

          startingX += thickness;
        }
      },
      []
    );

    const redraw = useCallback(
      (
        image: HTMLImageElement | undefined,
        imageFrame: PavementImageFrame | undefined,
        distanceRange: Range<number>,
        distressRecords: DistressRecord[],
        sessionEvents: SessionEvents | undefined
      ): DrawItems => {
        // console.log("redraw()");

        const drawItems: DrawItems = new DrawItems();

        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");

        if (!canvas || !context) {
          return drawItems;
        }

        const clearOusideArea = true;

        context.save();
        context.setTransform(1, 0, 0, 1, 0, 0); // identity matrix
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.restore();

        // context.beginPath();
        // context.rect(0, 0, 200, 200);
        // context.clip();

        //context.scale(2, 2);

        if (!image) {
          return drawItems;
        }

        //context.fillStyle = "red";
        //context.fillRect(1, 1, image.width - 2, image.height - 2);
        context.drawImage(image, 0, 0);

        if (!imageFrame) {
          return drawItems;
        }

        const scalePixelPerMeter = getScalePixelPerMeter(image, imageFrame);

        // same
        // console.log("imageFrame.frameLength", imageFrame.frameLength); // 6.096
        // console.log(
        //   "image.height / scalePixelPerMeter",
        //   image.height / scalePixelPerMeter
        // ); // 6.091863414634146

        if (showDistress) {
          drawDistress(
            context,
            image,
            imageFrame,
            scalePixelPerMeter,
            distressRecords,
            clearOusideArea,
            drawItems
          );
        }

        if (showEvents) {
          if (canShowConsideringPlayback(navigator, settings)) {
            drawEvents(
              context,
              image,
              imageFrame,
              scalePixelPerMeter,
              distanceRange,
              sessionEvents,
              drawItems
            );
            drawRangeEvents(
              context,
              image,
              imageFrame,
              scalePixelPerMeter,
              distanceRange,
              sessionEvents,
              drawItems
            );
          }
        }

        if (clearOusideArea) {
          //context.fillStyle = "red";
          //context.fillRect(0, 0, 100, 100)

          // clear before the image
          context.clearRect(
            0,
            -imageFrame.frameLength * scalePixelPerMeter * 2,
            imageFrame.frameWidth * scalePixelPerMeter,
            imageFrame.frameLength * scalePixelPerMeter * 2
          );
          // clear after the image
          context.clearRect(
            0,
            imageFrame.frameLength * scalePixelPerMeter - 1,
            imageFrame.frameWidth * scalePixelPerMeter,
            imageFrame.frameLength * scalePixelPerMeter * 2
          );
        }

        return drawItems;
      },
      [
        drawDistress,
        showDistress,
        drawEvents,
        drawRangeEvents,
        showEvents,
        navigator,
        settings,
      ]
    );

    const canShowConsideringPlayback = (
      navigator: Navigator,
      settings: Settings
    ) => {
      return (
        !navigator.isPlaying ||
        (navigator.isPlaying && settings.showPavementMarkingsDuringPlayback)
      );
    };

    useEffect(() => {
      const init = async () => {
        const loadImage = (src: string) => {
          return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.onload = (event: any) => resolve(event.target);
            //img.onerror = reject;
            // img.onerror = (ex) => {
            //   console.log("err", ex);
            // };
            img.src = src;
          });
        };

        // let image: HTMLImageElement | undefined = undefined;

        // if (isLoadFullsizeImage) {
        //   const fullImageUrl = imageFrame?.imageFullSizeUrl ?? "";
        //   const image = await loadImage(fullImageUrl);
        //   setImage(image);

        //   setIsInitFirstImage(true);
        //   initImageScale(image);
        // } else {

        // }

        //console.log("isLoadFullsizeImage", isLoadFullsizeImage);

        const fullImageUrl = imageFrame?.imageFullSizeUrl ?? "";
        const currentImageUrl = isLoadFullsizeImage ? fullImageUrl : imageUrl;

        const image = await loadImage(currentImageUrl);
        setImage(image);

        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");

        if (context) {
          context.setTransform(1, 0, 0, 1, 0, 0); // identity matrix
        }

        initImageScale(image);

        const drawItems = redraw(
          image,
          imageFrame,
          distanceRange,
          distressRecords,
          sessionEvents
        );
        setDrawItems(drawItems);
      };

      init();
    }, [
      imageUrl,
      isLoadFullsizeImage,
      imageFrame,
      distanceRange,
      distressRecords,
      sessionEvents,
      redraw,
    ]);

    useEffect(() => {
      //console.log(`camera[${cameraName}]:mount()`);

      const imagePreloader: ImagePreloader = new ImagePreloader();
      imagePreloader.setCameraName(cameraName);

      let currentIdSession: number | null = null;
      let currentBatchImageFrames: PavementImageFrame[] = [];
      let currentBatchImageFrameIndex: number = -1;

      const navigable = new Navigable();
      navigable.id = `camera[${cameraName}]`;

      let noImages: boolean = false;

      settings.addOnShowCameraImageUrlChangedCallback((checked: boolean) => {
        setShowCameraOrginalUrl(checked);
      });

      settings.onShowPavementDistressChanged = async (checked: boolean) => {
        setShowDistress(checked);

        if (checked) {
          if (navigator.navigatorPosition) {
            const distanceRange = getDistanceRange(navigator.navigatorPosition);
            const dr = await loadDistress(
              navigator.navigatorPosition,
              distanceRange
            );
            setDistressRecords(dr);
          }
        }
      };
      setShowDistress(settings.showPavementDistress);

      settings.onShowPavementDistressBoxesChanged = (checked: boolean) => {
        setShowDistressBoxes(checked);
      };
      setShowDistressBoxes(settings.showPavementDistressBoxes);

      settings.onShowPavementDistressLabelsChanged = (checked: boolean) => {
        setShowDistressLabels(checked);
      };
      setShowDistressLabels(settings.showPavementDistressLabels);

      settings.onShowPavementEventsChanged = async (checked: boolean) => {
        setShowEvents(checked);

        if (checked) {
          if (navigator.navigatorPosition) {
            const { data: se } = await eventService.getSessionEvents(
              navigator.navigatorPosition.idProject,
              navigator.navigatorPosition.idSession
            );
            setSessionEvents(se);
          }
        }
      };
      setShowEvents(settings.showPavementEvents);

      const getDistanceRange = (navigatorPosition: NavigatorPosition) => {
        const imageFrame = currentBatchImageFrames[0];

        const startDistance =
          navigatorPosition.distance - imageFrame.frameLength;
        const endDistance =
          navigatorPosition.distance + imageFrame.frameLength * 2;

        return new Range(startDistance, endDistance);
      };

      const loadData = async (navigatorPosition: NavigatorPosition) => {
        logService.log(`camera[${cameraName}]:loadData()`);
        try {
          const { data: sessionImageFrameIndex } =
            await pavementImageService.getImageFrameIndex(
              project.idProject,
              navigatorPosition.idSession,
              navigatorPosition.distance,
              cameraName
            );

          let startIndex =
            sessionImageFrameIndex - imageService.preloaderBackCacheSize * 10;
          if (startIndex < 0) {
            startIndex = 0;
          }
          const stopIndex = startIndex + imageService.batchSize;

          const { data: frames } = await pavementImageService.getImageFrames(
            project.idProject,
            navigatorPosition.idSession,
            cameraName,
            startIndex,
            stopIndex
          );

          currentBatchImageFrames = frames;

          currentBatchImageFrameIndex =
            NavigatorPosition.getClosestNavigatorPositionIndexExact(
              navigatorPosition,
              currentBatchImageFrames
            );

          // pavement camera position fix: pavement is in the back - no, there was the issue with 3 digits nav pos for pavement img in getClosestNavigatorPositionIndexExact()
          // if (
          //   currentBatchImageFrameIndex + 1 <
          //   currentBatchImageFrames.length - 1
          // ) {
          //   currentBatchImageFrameIndex++;
          // }

          noImages = frames.length === 0;

          if (noImages) {
            setImageUrl(ImageNotAvailableImage);
            setImageFrame(undefined);
            return;
          }

          const imageUrls = [];
          for (const frame of frames) {
            imageUrls.push(frame.imageUrl);
          }

          imagePreloader.setImageUrls(imageUrls);

          logService.log(`camera[${cameraName}]:loadData()-done`);
        } catch (error) {
          logService.log(error);
        }
      };

      const loadDistress = async (
        navigatorPosition: NavigatorPosition,
        distanceRange: Range<number>
      ) => {
        if (currentBatchImageFrames.length > 0) {
          const { data: dr } = await pavementImageService.getDistressRecords(
            navigatorPosition.idProject,
            navigatorPosition.idSession,
            distanceRange.getLowerBound(),
            distanceRange.getUpperBound()
          );

          return dr;
        }

        return [];
      };

      const navigatorMove: NavigatorMove = async (
        navigatorPosition: NavigatorPosition
      ) => {
        logService.log(`camera[${cameraName}]:navigator:move()`);

        const isSameIdSession =
          currentIdSession === navigatorPosition.idSession;

        if (isSameIdSession) {
          currentBatchImageFrameIndex =
            NavigatorPosition.getClosestNavigatorPositionIndexExact(
              navigatorPosition,
              currentBatchImageFrames
            );

          // pavement camera position fix: pavement is in the back - no, there was the issue with 3 digits nav pos for pavement img in getClosestNavigatorPositionIndexExact()
          // if (
          //   currentBatchImageFrameIndex + 1 <
          //   currentBatchImageFrames.length - 1
          // ) {
          //   currentBatchImageFrameIndex++;
          // }
        } else {
          currentBatchImageFrameIndex = -1;
          currentIdSession = navigatorPosition.idSession;
          noImages = false;
        }

        if (currentBatchImageFrameIndex === -1 && !noImages) {
          await loadData(navigatorPosition);
        }

        if (noImages) {
          return;
        }

        // load data

        const distanceRange = getDistanceRange(navigatorPosition);

        const imageUrl = await imagePreloader.getImage(
          currentBatchImageFrameIndex
        );

        let distressRecords: DistressRecord[] = [];

        if (settings.showPavementDistress && project.hasDistress) {
          if (canShowConsideringPlayback(navigator, settings)) {
            let dr = await loadDistress(navigatorPosition, distanceRange);
            distressRecords = dr;
          }
        }

        let sessionEvents: SessionEvents | undefined = undefined;

        const showPavementEvents = settings.showPavementEvents;

        if (showPavementEvents && project.hasDistress) {
          if (!isSameIdSession) {
            const { data: se } = await eventService.getSessionEvents(
              navigatorPosition.idProject,
              navigatorPosition.idSession
            );
            sessionEvents = se;
          }
        }

        // update state

        if (imageUrl && imageUrl !== ImageCacheItem.LOADING) {
          setIsLoadFullsizeImage(false);

          setImageUrl(imageUrl);

          const imageOriginalUrl = imagePreloader.getImageOriginalUrl(
            currentBatchImageFrameIndex
          );
          setImageOriginalUrl(imageOriginalUrl);

          setImageFrame(
            currentBatchImageFrames.find((f) => f.imageUrl === imageOriginalUrl)
          );
        }

        setDistanceRange(distanceRange);

        setDistressRecords(distressRecords);

        if (showPavementEvents) {
          if (!isSameIdSession) {
            setSessionEvents(sessionEvents);
          }
        }

        logService.log(`camera[${cameraName}]:navigator:move()-done`);
      };

      navigable.navigatorMove = navigatorMove;

      const playbackIsPlayingChanged = async (isPlaying: boolean) => {
        if (settings.showPavementDistress && project.hasDistress) {
          if (!settings.showPavementMarkingsDuringPlayback) {
            if (!isPlaying) {
              if (navigator.navigatorPosition) {
                const distanceRange = getDistanceRange(
                  navigator.navigatorPosition
                );
                const dr = await loadDistress(
                  navigator.navigatorPosition,
                  distanceRange
                );
                setDistressRecords(dr);
              }
            }
          }
        }
      };
      navigable.playbackIsPlayingChanged = playbackIsPlayingChanged;

      // const navigatorMoveAllCompleted: NavigatorMoveAllCompleted = async () => {
      //   logService.log(`camera[${cameraName}]:MoveAllCompleted`);

      //   if (imageFrameIndex !== -1) {
      //     imagePreloader.preloadImages(imageFrameIndex);
      //   }
      // };
      // navigable.navigatorMoveAllCompleted = navigatorMoveAllCompleted;

      navigator.attach(navigable);

      // this sould be called on open/close window
      if (
        navigator.navigatorPosition &&
        navigator.navigatorPosition.idProject === project.idProject
      ) {
        logService.log(`camera[${cameraName}]:navigator:move()-init`);
        navigatorMove(navigator.navigatorPosition);
      }

      return () => {
        //console.log(`camera[${cameraName}]:unmount()`);
        navigator.detach(navigable);
        imagePreloader.clearCache();
      };
    }, [cameraName, navigator, project, settings]);

    const getTransformedPoint = (x: number, y: number): any => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (!context) {
        return;
      }

      const originalPoint = new DOMPoint(x, y);
      return context.getTransform().invertSelf().transformPoint(originalPoint);
    };

    let isDragging = false;
    let dragStartPosition = { x: 0, y: 0 };
    let currentTransformedCursor: any;

    const onMouseDown = (event: any) => {
      isDragging = true;
      dragStartPosition = getTransformedPoint(
        event.nativeEvent.offsetX,
        event.nativeEvent.offsetY
      );
    };

    const showTooltip = (
      mouseX: number,
      mouseY: number,
      tooltipHtml: string
    ) => {
      //console.log("showTooltip", tooltipHtml);
      const tooltipOffset = 10;
      if (canvasTooltipRef.current) {
        canvasTooltipRef.current.style.display = "initial";
        canvasTooltipRef.current.style.left = mouseX + tooltipOffset + "px";
        canvasTooltipRef.current.style.top = mouseY + tooltipOffset + "px";
        canvasTooltipRef.current.innerHTML = tooltipHtml;
      }
    };

    const hideTooltip = () => {
      if (canvasTooltipRef.current) {
        canvasTooltipRef.current.style.display = "none";
      }
    };

    const showDistressTooltip = (
      mouseX: number,
      mouseY: number,
      mouseXtransformed: number,
      mouseYtransformed: number
    ): boolean => {
      let isHit = false;

      for (const distressLine of drawItems.distressLines) {
        isHit = geometryHelper.isPointOnLine(
          distressLine.x1,
          distressLine.y1,
          distressLine.x2,
          distressLine.y2,
          mouseXtransformed,
          mouseYtransformed
        );
        if (isHit) {
          //console.log("hit", distressLine.d);

          let toolTip = "";

          // canvasTooltipRef.current.innerText =
          //   distressLine.d.idDistressRecord.toString();

          const chainage = roadManager.getChainageForDistance(
            navigator.navigatorPosition?.idSession ?? 0,
            distressLine.d.distance,
            navigator.roadLocatorSegments
          );

          toolTip += `Distress<br/>
            ${distressLine.d.distressSeverity.distressType.distressTypeName} ${
            distressLine.d.distressSeverity.severityName
          }<br/>
            Chainage: ${numberHelper.numberTo3Digits(
              unitConverter.metersToMiles(chainage)
            )} [mi]<br/>
            Length: ${numberHelper.numberTo3Digits(
              unitConverter.metersToFeet(distressLine.d.length)
            )} [ft]<br/>`;

          if (
            distressLine.d.distressSeverity.distressType.distressCategory ===
            DistressCategory.Area
          ) {
            const width = distressLine.d.x2 - distressLine.d.x1;
            const area =
              Math.abs(distressLine.d.x2 - distressLine.d.x1) *
              Math.abs(distressLine.d.y2 - distressLine.d.y1);
            toolTip += `Width: ${numberHelper.numberTo3Digits(
              unitConverter.metersToFeet(width)
            )} [ft]<br/>
              Area: ${numberHelper.numberTo3Digits(
                unitConverter.metersSquareToFeetSquare(area)
              )} [ft^2]<br/>`;
          }

          if (settings.isChainageInMeters) {
            toolTip += `Distance: ${numberHelper.numberTo3Digits(
              distressLine.d.distance
            )} [m]`;
          }

          showTooltip(mouseX, mouseY, toolTip);

          break;
        }
      }

      return isHit;
    };

    const showEventTooltip = (
      mouseX: number,
      mouseY: number,
      mouseXtransformed: number,
      mouseYtransformed: number
    ): boolean => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (!context) {
        return false;
      }

      const currentTransform = getCurrentTransform(context);
      const scaleX = currentTransform.scaleX;
      // const scaleY = currentTransform.scaleX;

      let isHit = false;

      for (const eventPoint of drawItems.eventPoints) {
        const x = eventPoint.x / scaleX;
        const y = eventPoint.y;

        // context.fillStyle = "pink";
        // context.fillRect(x, y, 3 / scaleX, 3 / scaleY);

        isHit = geometryHelper.isPointCloseToPoint(
          x,
          y,
          mouseXtransformed,
          mouseYtransformed,
          10 / scaleX
        );

        if (isHit) {
          let toolTip = "";

          const chainage = roadManager.getChainageForDistance(
            navigator.navigatorPosition?.idSession ?? 0,
            eventPoint.e.distance,
            navigator.roadLocatorSegments
          );

          toolTip += `Event<br/>
            ${eventPoint.e.eventTypeName}<br/>
            Chainage: ${numberHelper.numberTo3Digits(
              unitConverter.metersToMiles(chainage)
            )} [mi]<br/>`;

          if (settings.isChainageInMeters) {
            toolTip += `Distance: ${numberHelper.numberTo3Digits(
              eventPoint.e.distance
            )} [m]`;
          }

          showTooltip(mouseX, mouseY, toolTip);
          break;
        }
      }

      return isHit;
    };

    const onMouseMove = (event: any) => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (!context) {
        return;
      }

      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;

      currentTransformedCursor = getTransformedPoint(mouseX, mouseY);

      const mouseXtransformed = currentTransformedCursor.x;
      const mouseYtransformed = currentTransformedCursor.y;

      if (isDragging) {
        context.translate(
          currentTransformedCursor.x - dragStartPosition.x,
          currentTransformedCursor.y - dragStartPosition.y
        );
        redraw(
          image,
          imageFrame,
          distanceRange,
          distressRecords,
          sessionEvents
        );
      }

      //console.log("mouse-move-cursor", currentTransformedCursor);
      //console.log("mouse-move", distressLines);

      let isHit = false;

      isHit = showDistressTooltip(
        mouseX,
        mouseY,
        mouseXtransformed,
        mouseYtransformed
      );

      if (!isHit) {
        isHit = showEventTooltip(
          mouseX,
          mouseY,
          mouseXtransformed,
          mouseYtransformed
        );
      }

      if (!isHit) {
        hideTooltip();
      }
    };

    const onMouseUp = (event: any) => {
      isDragging = false;
    };

    const onWheel = (event: any) => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (!canvas || !context || !image) {
        return;
      }

      let zoom = event.deltaY < 0 ? 1.1 : 0.9;

      // context.save();
      // context.scale(zoom, zoom);
      // const currentTransformTemp = context.getTransform();
      // const vScaleNew = currentTransformTemp.m22;
      // context.restore();

      // var vRatio = canvas.height / image.height;

      // if (vScaleNew < vRatio) {
      //   return;
      // }

      context.translate(currentTransformedCursor.x, currentTransformedCursor.y);
      context.scale(zoom, zoom);
      context.translate(
        -currentTransformedCursor.x,
        -currentTransformedCursor.y
      );

      redraw(image, imageFrame, distanceRange, distressRecords, sessionEvents);

      //event.preventDefault();
      return false;
    };

    const onHdImageLoad = async () => {
      setIsLoadFullsizeImage(true);
    };

    const onFullScreen = async () => {
      setIsLoadFullsizeImage(true);

      if (document.fullscreenElement) {
        setIsFullScreen(false);
        document.exitFullscreen();
      } else {
        setIsFullScreen(true);
        camContainerRef.current?.requestFullscreen();
      }
    };

    const onResetZoom = async () => {
      refresh();
    };

    const onLayersMenuClick = async () => {
      setShowCameraOptions(!showCameraOptions);
    };

    return (
      <div ref={camContainerRef} style={{ height: "100%", width: "100%" }}>
        {showCameraOrginalUrl && (
          <div
            style={{
              position: "absolute",
              background: "white",
              opacity: "0.8",
              zIndex: 1,
            }}
          >
            {imageOriginalUrl}
          </div>
        )}
        <div
          className="d-flex flex-column"
          style={{ position: "absolute", top: "4px", left: "4px" }}
        >
          <div>
            {!isFullScreen && (
              <div
                className={
                  "image-button" +
                  (isLoadFullsizeImage ? " image-button-active" : "")
                }
                style={{ marginBottom: "4px" }}
                onClick={onHdImageLoad}
              >
                <img style={{ height: "18px" }} src={ImageHd} alt="" />
              </div>
            )}
          </div>
          <div>
            <div
              className="image-button"
              style={{ marginBottom: "4px" }}
              onClick={onFullScreen}
            >
              <img
                style={{ height: "18px" }}
                src={!isFullScreen ? FullScreen : FullScreenExit}
                alt=""
              />
            </div>
          </div>
          <div>
            <div
              className="image-button"
              style={{ marginBottom: "4px" }}
              onClick={onResetZoom}
            >
              <img style={{ height: "18px" }} src={ZoomFit} alt="" />
            </div>
          </div>
          {project.hasDistress && (
            <div className="d-flex">
              <div
                className={
                  "image-button" // + (showCameraSettings ? " image-button-active" : "")
                }
                style={{ marginBottom: "4px" }}
                onClick={onLayersMenuClick}
              >
                <i className="navbar-nav-fa fa fa-sliders fa-lg"></i>
              </div>
              {showCameraOptions && (
                <div
                  className="ms-1"
                  style={{
                    background: "white",
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "0.25rem",
                  }}
                >
                  <CameraPavementOptions settings={settings} />
                </div>
              )}
            </div>
          )}
        </div>

        {/* <div className="camera-img-container">
          {imageUrl && <img className="camera-img" src={imageUrl} alt="" />}
        </div> */}

        <canvas
          ref={canvasRef}
          //style={{ border: "1px solid blue", height: "100%", width: "100%" }}
          style={{
            height: "100%",
            width: "100%",
            //imageRendering: "pixelated",
          }}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onWheel={onWheel}
        ></canvas>
        <div
          style={{
            display: "none",
            position: "absolute",
            border: "1px solid grey",
            padding: "2px 5px",
            background: "white",
            pointerEvents: "none",
          }}
          ref={canvasTooltipRef}
        >
          Tooltip
        </div>
      </div>
    );
  }
);

export default CameraPavement;
